.code-editor {
  font-size: 1.125rem;
  font-size: 15px;
  position: relative;
  line-height: 1.2; }
  .code-editor .CodeMirror {
    height: auto;
    transition: background-color 0.2s; }
  .code-editor .CodeMirror-gutters {
    transition: background-color 0.2s; }
  .code-editor[data-selected="true"] .CodeMirror {
    box-shadow: 0 0 0 5px HSL(214, 78%, 84%); }
  .code-editor[data-theme="scribe"] .CodeMirror-linenumber {
    padding: 0 2px 0px 0px;
    min-width: 28px; }
  .code-editor[data-theme="scribe"] .CodeMirror-sizer {
    margin-left: 32px;
    padding-top: 28px;
    padding-bottom: 28px !important; }
  .code-editor[data-theme="scribe"] .CodeMirror pre.CodeMirror-line {
    padding: 0 14px; }
  .code-editor[data-theme="scribe"] .cm-s-scribe .CodeMirror-gutters {
    width: 36px; }
  .code-editor[data-mounted="true"] .code-editor-select {
    visibility: visible;
    opacity: 1; }
  .code-editor .code-editor-select {
    position: absolute;
    bottom: 6px;
    right: 12px;
    transition: opacity 0.2s ease-out;
    visibility: hidden;
    opacity: 0; }
  .code-editor .select-icon-container {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    display: flex; }
    .code-editor .select-icon-container svg {
      width: 10px; }
  .code-editor .select-css {
    display: block;
    font-size: 13px;
    letter-spacing: 0.1px;
    font-weight: 600;
    color: white;
    line-height: 1;
    padding: 0.3em 1.8rem 0.3em 0.7em;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #0e2339;
    z-index: 20;
    transition: color 0.2s, background-color 0.2s; }
  .code-editor .select-css::-ms-expand {
    display: none; }
  .code-editor .select-css:not([disabled]):hover {
    background-color: rgba(255, 255, 255, 0.3); }
  .code-editor .select-css:focus {
    background-color: rgba(255, 255, 255, 0.35);
    outline: none; }
  .code-editor .select-css option {
    font-weight: normal; }
