.image-editor {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1rem; }
  .image-editor .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
  .image-editor .save-btn-container {
    width: 100px; }
  .image-editor .view-stack {
    pointer-events: none;
    outline: transparent; }
  .image-editor .editor {
    width: 100%;
    height: 100%; }
