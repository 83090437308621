.login-page {
  padding: 3.375rem 2rem 0;
  background: white;
  position: relative; }
  .login-page .google-btn {
    padding: 11px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    font-size: 14px;
    font-family: Roboto, arial, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    letter-spacing: 0.22px;
    width: 100%;
    justify-content: center;
    text-decoration: none;
    height: 40px; }
  .login-page .google-icon {
    width: 18px;
    height: 18px;
    margin-right: 21px; }
  .login-page .container {
    max-width: 360px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12vh;
    margin-bottom: 80px; }
  .login-page .error-message-container {
    margin-bottom: 1.5rem; }
  .login-page .heading {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem; }
  .login-page .seperator {
    margin: 2rem 0 1rem 0;
    height: 1px;
    background: var(--highlight-200);
    width: 100%; }
  .login-page .email-login-container,
  .login-page .email-reset-password-container {
    background: white;
    width: 100%; }
  .login-page .form-btns-container {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; }
    @media screen and (max-width: 365px) {
      .login-page .form-btns-container {
        justify-content: center;
        flex-direction: column;
        align-items: center; }
        .login-page .form-btns-container .secondary-btn-container {
          margin-right: 0; }
        .login-page .form-btns-container .main-btn-container {
          flex: initial; } }
  .login-page .secondary-btn-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
    margin-top: 20px;
    margin-right: 20px;
    flex: 1 0 auto; }
  .login-page .secondary-btn {
    padding: 0;
    border: 0;
    -webkit-appearance: none;
    color: var(--primary-500);
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.5px; }
    .login-page .secondary-btn:hover {
      color: var(--primary-400); }
  .login-page .main-btn-container {
    display: flex;
    justify-content: flex-end;
    min-width: 160px; }
  .login-page .sent-reset-email-message {
    text-align: center;
    margin-top: 1rem;
    color: var(--neutral-600); }
