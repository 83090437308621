.signup-page {
  padding: 3.375rem 2rem 0;
  background: white; }
  .signup-page .google-btn {
    padding: 11px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    font-size: 14px;
    font-family: Roboto, arial, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    letter-spacing: 0.22px;
    width: 100%;
    justify-content: center;
    text-decoration: none; }
  .signup-page .google-icon {
    width: 18px;
    height: 18px;
    margin-right: 21px; }
  .signup-page .container {
    max-width: 360px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12vh;
    margin-bottom: 80px; }
  .signup-page .heading {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem; }
  .signup-page .seperator {
    margin: 2rem 0 1rem 0; }
  .signup-page .email-signup-container {
    background: white;
    width: 100%; }
  .signup-page .signup-btn-container {
    max-width: 200px;
    margin-left: auto;
    margin-top: 0.875rem; }
