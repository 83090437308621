.filter-chips-section {
  position: relative; }
  .filter-chips-section .filter-chips {
    margin: -8px;
    max-height: 80px;
    overflow: hidden; }
    .filter-chips-section .filter-chips[data-expanded="true"] {
      overflow: visible;
      max-height: 1000px; }
    .filter-chips-section .filter-chips > * {
      margin: 8px; }
    @media screen and (max-width: 600px) {
      .filter-chips-section .filter-chips {
        margin: -5px;
        max-height: 70px; }
        .filter-chips-section .filter-chips > * {
          margin: 5px; } }
  .filter-chips-section .expand-btn {
    border-radius: 1.25rem;
    padding: 0.25rem 0.875rem;
    background: white;
    color: var(--highlight-500);
    font-size: 0.75rem;
    font-weight: 600;
    border: 1px solid var(--highlight-500);
    letter-spacing: 0.5px;
    cursor: pointer; }
  .filter-chips-section .detection-container {
    visibility: hidden;
    display: inline-block;
    position: relative; }

@-webkit-keyframes appear {
  0% {
    transform: translateX(-10%);
    opacity: 0.01; }
  20% {
    transform: translateX(-4%);
    opacity: 0.9; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes appear {
  0% {
    transform: translateX(-10%);
    opacity: 0.01; }
  20% {
    transform: translateX(-4%);
    opacity: 0.9; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes initialAppear {
  0% {
    opacity: 0.01; }
  30% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }

@keyframes initialAppear {
  0% {
    opacity: 0.01; }
  30% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }
  .filter-chips-section .filter-chips[data-expanded="true"] [data-filter-chip].animate {
    -webkit-animation: 0.5s ease-out 0s 1 appear;
            animation: 0.5s ease-out 0s 1 appear; }
