.page-edit-page {
  padding: 60px 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto; }
  @media (max-width: 1199px) {
    .page-edit-page {
      padding: 40px 0 0; } }
  .page-edit-page .editor-container {
    margin: 2rem 0 0;
    flex: 1 1 auto;
    display: flex; }
  .page-edit-page [data-slate-editor="true"]:not([data-code-block]) {
    padding-bottom: calc(70px + 5vh); }
    @media (min-width: 1200px) {
      .page-edit-page [data-slate-editor="true"]:not([data-code-block]) {
        padding-bottom: 360px; } }
  .page-edit-page .private-bookmark-container {
    display: flex; }
  .page-edit-page .floating-extras {
    width: 100%;
    position: fixed;
    top: 250px;
    left: 0;
    display: flex;
    justify-content: center;
    pointer-events: none; }
    .page-edit-page .floating-extras[data-nav-collapsed="true"] .floating-extras-area {
      max-width: 730px;
      width: 100%; }
      @media (min-width: 577px) {
        .page-edit-page .floating-extras[data-nav-collapsed="true"] .floating-extras-area {
          max-width: 940px; } }
      @media screen and (max-width: 1130px) {
        .page-edit-page .floating-extras[data-nav-collapsed="true"] .floating-extras-area {
          display: none; } }
    .page-edit-page .floating-extras[data-nav-collapsed="false"] .floating-extras-area {
      max-width: 620px;
      width: 100%; }
      @media (min-width: 577px) {
        .page-edit-page .floating-extras[data-nav-collapsed="false"] .floating-extras-area {
          max-width: 790px; } }
      @media screen and (max-width: 1280px) {
        .page-edit-page .floating-extras[data-nav-collapsed="false"] .floating-extras-area {
          display: none; } }
    .page-edit-page .floating-extras .floating-extras-content {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start; }
  .page-edit-page .clap-btn-container {
    width: 54px;
    display: flex;
    pointer-events: all;
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    justify-content: flex-start; }
  .page-edit-page .bookmark-container {
    margin-left: 5px; }
  .page-edit-page .total-clap-count {
    margin-left: 8px;
    color: var(--neutral-600); }
  .page-edit-page .center-container {
    display: flex;
    justify-content: center; }
  .page-edit-page .page-connection-container {
    max-width: 44rem;
    margin: 0 4rem;
    width: 100%;
    min-height: 66px;
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
    .page-edit-page .page-connection-container > :not(:last-child) {
      margin-bottom: 1.25rem; }
    @media (min-width: 577px) and (max-width: 1199px) {
      .page-edit-page .page-connection-container {
        margin: 0 3rem; } }
    @media (max-width: 576px) {
      .page-edit-page .page-connection-container {
        margin: 0 1.5rem; } }
  .page-edit-page .loading-block-paragraph {
    border-radius: 0.5rem;
    height: 30px;
    width: 100%;
    max-width: 32rem;
    width: 80%; }
  .page-edit-page .loading-block-title {
    border-radius: 0.5rem;
    height: 80px;
    width: 100%; }
  .page-edit-page .loading-container {
    max-width: 48rem;
    margin: 1rem auto;
    padding: 0 2rem;
    width: 100%; }
    .page-edit-page .loading-container > :not(:last-child) {
      margin-bottom: 2rem; }
  .page-edit-page .animate {
    -webkit-animation: shimmer 2s infinite linear;
            animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, var(--neutral-100) 4%, var(--neutral-200) 25%, var(--neutral-100) 36%);
    background-size: 1000px 100%;
    -webkit-animation-delay: calc(var(--order) * 20ms);
            animation-delay: calc(var(--order) * 20ms); }

@-webkit-keyframes shimmer {
  0% {
    background-position: -1000px 0; }
  100% {
    background-position: 1000px 0; } }

@keyframes shimmer {
  0% {
    background-position: -1000px 0; }
  100% {
    background-position: 1000px 0; } }

.status-badge {
  display: inline-flex;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
  margin-right: 0.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  --color: var(--highlight-600);
  color: var(--color); }
  .status-badge[data-archived="true"][data-shared="false"] {
    --color: var(--neutral-600); }
  .status-badge[data-deleted="true"][data-shared="false"] {
    --color: var(--danger-500); }

.tablet-more-options-btn-popover {
  background: white;
  padding: 10px 0;
  min-width: 150px; }
  .tablet-more-options-btn-popover .tablet-more-options-btn {
    text-decoration: none;
    color: var(--neutral-700);
    font-weight: 600;
    padding: 0 20px;
    line-height: 2.2;
    display: block;
    border: none;
    background: inherit;
    text-align: left;
    width: 100%;
    -webkit-appearance: none;
    display: flex;
    align-items: center; }
    .tablet-more-options-btn-popover .tablet-more-options-btn > svg {
      margin-right: 0.625rem; }
    .tablet-more-options-btn-popover .tablet-more-options-btn:hover {
      background: var(--neutral-100); }
    .tablet-more-options-btn-popover .tablet-more-options-btn.danger {
      color: var(--danger-400); }

.delete-page-confirmation-dialog {
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  height: 100%;
  width: 80vw;
  max-width: 480px;
  background: white;
  border-radius: 8px; }
  .delete-page-confirmation-dialog .modal-heading {
    font-size: 1.75rem;
    font-weight: 700; }
  .delete-page-confirmation-dialog .action-btns-container {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 14px;
    justify-content: end;
    margin-top: 2.5rem; }

.related-entity-section {
  display: flex; }
  .related-entity-section .entity-label {
    font-weight: 600;
    margin-right: 0.75rem;
    line-height: 23px;
    width: 120px;
    text-align: right;
    flex: 0 0 auto; }
  .related-entity-section .entities {
    display: flex;
    max-width: 640px;
    flex-wrap: wrap;
    margin: -4px -6px; }
    .related-entity-section .entities > * {
      margin: 4px 6px; }
  .related-entity-section .entity-item {
    max-width: 200px; }
