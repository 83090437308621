.lp-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 6;
  background: inherit; }
  .lp-navbar .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
    height: 3.375rem;
    max-width: 65rem;
    width: 100%;
    margin: 0 auto;
    background: inherit; }
    @media screen and (max-width: 630px) {
      .lp-navbar .navbar-content {
        padding: 0 20px 0 20px; } }
  .lp-navbar[data-top="false"]::before, .lp-navbar[data-border="true"]::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
    z-index: 5;
    pointer-events: none; }
  .lp-navbar[data-show="false"] {
    opacity: 0;
    box-shadow: none; }
  .lp-navbar[data-mode="access"] {
    background: white; }
    .lp-navbar[data-mode="access"] .navbar-content {
      max-width: 85rem;
      padding: 0 4rem; }
      @media screen and (max-width: 630px) {
        .lp-navbar[data-mode="access"] .navbar-content {
          padding: 0 20px 0 20px; } }
  .lp-navbar .navbar-links {
    display: flex; }
    .lp-navbar .navbar-links li {
      display: flex;
      align-items: center; }
    .lp-navbar .navbar-links li + li {
      margin-left: 3rem; }
    @media screen and (max-width: 630px) {
      .lp-navbar .navbar-links {
        display: none; } }
  .lp-navbar .navbar-link {
    text-decoration: none;
    color: white; }
    .lp-navbar .navbar-link[data-active="false"]:hover {
      color: #f0bf75 !important; }
    .lp-navbar .navbar-link[data-active="true"]:hover {
      color: #eba947 !important; }
  .lp-navbar[data-theme="light"] .navbar-link {
    color: var(--neutral-900); }
    .lp-navbar[data-theme="light"] .navbar-link[data-active="true"] {
      color: var(--primary-500); }
    .lp-navbar[data-theme="light"] .navbar-link[data-active="true"]:hover {
      color: var(--primary-300) !important; }
  .lp-navbar .logo-link {
    display: flex;
    text-decoration: none;
    color: var(--neutral-900);
    align-items: center;
    padding-left: 1rem; }
  .lp-navbar .scribe-icon {
    height: 1.5rem;
    margin-right: 3px; }
  .lp-navbar .logo {
    font-size: 1.5rem; }
    @media screen and (max-width: 630px) {
      .lp-navbar .logo {
        color: white; } }
  .lp-navbar[data-theme="light"] .logo {
    color: var(--neutral-900); }
  .lp-navbar[data-mode="access"] .logo {
    color: var(--neutral-800); }
  .lp-navbar .content__right {
    display: flex;
    height: 100%;
    align-items: center; }
  .lp-navbar .log-in-btn-container {
    color: var(--primary-700); }
    @media screen and (max-width: 630px) {
      .lp-navbar .log-in-btn-container {
        display: none; } }
  .lp-navbar .menu-icon-container {
    color: white;
    border: none;
    background: inherit; }
    .lp-navbar .menu-icon-container > svg {
      height: 100%;
      width: 20px; }
    @media screen and (min-width: 630px) {
      .lp-navbar .menu-icon-container {
        display: none; } }
  .lp-navbar[data-theme="light"] .menu-icon-container {
    color: var(--neutral-900); }
  @media screen and (max-width: 370px) {
    .lp-navbar .request-access-btn-container .text-small {
      display: inline-block; }
    .lp-navbar .request-access-btn-container .text {
      display: none; } }
  @media screen and (min-width: 370px) {
    .lp-navbar .request-access-btn-container .text-small {
      display: none; }
    .lp-navbar .request-access-btn-container .text {
      display: inline-block; } }

.menu {
  transition: opacity 0.2s;
  z-index: 200;
  position: absolute;
  left: 16px;
  right: 16px;
  top: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1);
  opacity: 0.98;
  overflow: hidden; }
  .menu[data-visible="false"] {
    visibility: hidden;
    opacity: 0; }
  .menu .menu-links {
    list-style: none;
    padding: 0;
    padding: 1rem 2rem 0.5rem 1rem; }
    .menu .menu-links > li {
      display: flex; }
  .menu .menu-link {
    text-decoration: none;
    padding: 0 20px 0 10px;
    line-height: 40px;
    font-size: 1rem;
    color: var(--neutral-800); }
    .menu .menu-link[data-active="true"] {
      color: var(--primary-500); }
  .menu .login-btn {
    background: var(--primary-500);
    color: white;
    border: 1px solid var(--primary-500);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    height: 2.75rem;
    width: 100%;
    padding: 0.5rem 1.25rem;
    text-decoration: none; }
  .menu .close-button {
    position: absolute;
    display: flex;
    right: 9px;
    border: none;
    font-size: 2rem;
    line-height: 2rem;
    top: 7px;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    z-index: 2;
    cursor: pointer; }
    .menu .close-button > svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--neutral-500);
      transition: all 0.3s; }
