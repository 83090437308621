.landing-page {
  overflow: hidden; }
  @media screen and (max-width: 630px) {
    .landing-page {
      --input-color: white;
      --border-color: var(--highlight-400);
      --label-color: var(--neutral-200);
      --label-color-focus: var(--highlight-500); } }
  .landing-page .container {
    max-width: 65rem;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex; }
  .landing-page .container.section {
    max-width: 85rem;
    padding: 0 4rem; }
    @media screen and (max-width: 630px) {
      .landing-page .container.section {
        padding: 0 2rem; } }
  .landing-page .container.small {
    max-width: 43rem; }
  .landing-page .container.vertical {
    flex-direction: column; }
  .landing-page .background-shape {
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
    .landing-page .background-shape > svg {
      height: 761px;
      flex: 0 0 auto; }
    @media screen and (max-width: 630px) {
      .landing-page .background-shape > svg {
        margin-left: calc(100vw - 900px); } }
    @media screen and (max-width: 630px) {
      .landing-page .background-shape {
        top: 120px;
        transform: scale(-1, 1.4); } }
  .landing-page .cls-1 {
    fill: none; }
  .landing-page .cls-2 {
    fill: var(--primary-700);
    transition: all 0.8s; }
  .landing-page .title-signup {
    flex: 1 0 auto;
    margin-right: 80px;
    margin-bottom: 60px;
    max-width: 31rem;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 1200px) {
      .landing-page .title-signup {
        margin-right: 0; } }
  .landing-page .main-title {
    font-size: 4rem;
    font-weight: 700;
    color: var(--highlight-500);
    line-height: 1.18;
    width: 99%;
    background: linear-gradient(135deg, var(--highlight-500), var(--highlight-400));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
            background-clip: text; }
    @media screen and (max-width: 630px) {
      .landing-page .main-title {
        text-transform: uppercase;
        letter-spacing: 0.8px;
        line-height: 1.3;
        font-size: 2rem; } }
  .landing-page .signup-form {
    display: flex;
    margin-top: 6rem;
    position: relative; }
    @media screen and (max-width: 1200px) {
      .landing-page .signup-form {
        margin-top: 4rem; } }
    @media screen and (max-width: 630px) {
      .landing-page .signup-form {
        margin-top: 2rem; } }
    @media screen and (max-width: 450px) {
      .landing-page .signup-form {
        flex-direction: column; } }
  .landing-page .signup-form[data-requested-access="true"] {
    flex-direction: column; }
    .landing-page .signup-form[data-requested-access="true"] .thank-you {
      visibility: visible;
      opacity: 1; }
    .landing-page .signup-form[data-requested-access="true"] .input-container,
    .landing-page .signup-form[data-requested-access="true"] .button-container {
      visibility: hidden;
      opacity: 0; }
  .landing-page .thank-you {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    justify-self: center;
    align-self: center;
    width: calc(100% + 4rem);
    text-align: center;
    margin: 0 -2rem 0 0;
    padding: 10px 10px;
    background: white; }
    @media screen and (max-width: 450px) {
      .landing-page .thank-you {
        box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1); } }
    .landing-page .thank-you > :first-child {
      font-size: 1.25rem;
      color: var(--primary-500); }
    .landing-page .thank-you > :last-child {
      font-size: 1.125rem;
      color: var(--neutral-500); }
    .landing-page .thank-you > p {
      margin: 0; }
  .landing-page .input-container {
    flex: 1 1 auto;
    margin-right: 1.5rem; }
    @media screen and (max-width: 450px) {
      .landing-page .input-container {
        margin-right: 30%; } }
    @media screen and (max-width: 380px) {
      .landing-page .input-container {
        margin-right: 22%; } }
    @media screen and (max-width: 340px) {
      .landing-page .input-container {
        margin-right: 8%; } }
  .landing-page .hero {
    width: 100%;
    overflow: hidden;
    padding-bottom: 1rem; }
  .landing-page .hero-image-container {
    align-self: flex-start;
    box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1); }
  .landing-page .hero-image {
    max-width: 850px;
    height: auto; }
    @media screen and (max-width: 1200px) {
      .landing-page .hero-image {
        width: 100%;
        max-width: none; } }
  .landing-page .hero-content {
    margin-top: 8rem; }
    @media screen and (max-width: 1200px) {
      .landing-page .hero-content {
        flex-direction: column;
        margin-top: 6rem; } }
  .landing-page .button-container {
    display: flex;
    padding-top: 4px; }
  .landing-page .pitch {
    margin-top: 90px;
    line-height: 1.7; }
    .landing-page .pitch .title {
      margin-bottom: 1rem;
      text-align: center; }
  .landing-page .shape {
    transition: transform 1.2s, opacity 0.8s;
    z-index: -1;
    position: absolute; }
  .landing-page .circle {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: var(--primary-100);
    opacity: 0; }
  .landing-page .circle.small {
    width: 250px;
    height: 250px; }
  .landing-page .shape-circle-1 {
    left: 0;
    bottom: 0;
    transform: translate3d(-35%, 40%, 0); }
  .landing-page .shape-circle-1.in-view {
    transform: translate3d(-15%, 20%, 0);
    opacity: 1; }
  .landing-page .shape-circle-2 {
    right: 0;
    bottom: 0;
    transform: translate3d(35%, 20%, 0); }
  .landing-page .shape-circle-2.in-view {
    transform: translate3d(15%, 0%, 0);
    opacity: 1; }
  .landing-page .section {
    position: relative; }
  .landing-page #features {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -110px;
    left: 0; }
  .landing-page .features-section {
    position: relative; }
    .landing-page .features-section > .section:first-child {
      margin-top: 90px; }
    .landing-page .features-section > .section:not(:first-child) {
      margin-top: 140px; }
    .landing-page .features-section > :nth-child(odd) .feature-img-container {
      order: 1; }
    @media screen and (max-width: 1080px) {
      .landing-page .features-section > :nth-child(odd) .feature-img-container {
        order: 0; } }
  .landing-page .feature-container {
    display: flex; }
    .landing-page .feature-container > :first-child {
      margin-right: 3rem; }
    @media screen and (max-width: 1080px) {
      .landing-page .feature-container {
        flex-direction: column; }
        .landing-page .feature-container > :first-child {
          margin-right: 0; } }
  .landing-page .features-section > :nth-child(odd) > .feature-container > :last-child {
    margin-right: 3rem; }
  .landing-page .features-section > :nth-child(odd) > .feature-container > :first-child {
    margin-right: 0; }
  .landing-page .shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .landing-page .feature-img-container {
    align-self: flex-start;
    box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1);
    position: relative; }
  .landing-page .coming-soon-text {
    font-size: 2.5rem;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-37%, -55%);
    font-weight: 700;
    color: var(--highlight-500); }
    @media screen and (max-width: 720px) {
      .landing-page .coming-soon-text {
        font-size: 2rem; } }
    @media screen and (max-width: 540px) {
      .landing-page .coming-soon-text {
        font-size: 1.25rem; } }
  .landing-page .feature-image {
    max-width: 100%;
    height: auto;
    outline: none; }
    .landing-page .feature-image.blank {
      filter: grayscale(2) blur(3px); }
  .landing-page .feature-text-container {
    max-width: 26.5rem;
    flex: 1 0 auto; }
    .landing-page .feature-text-container > h3 {
      font-size: 2.25rem;
      font-weight: 600;
      color: var(--primary-700);
      margin-bottom: 0.875rem;
      margin-top: 18%; }
      @media screen and (max-width: 1080px) {
        .landing-page .feature-text-container > h3 {
          font-size: 2rem;
          margin-top: 1.5rem; } }
    .landing-page .feature-text-container > p {
      line-height: 1.7; }
  .landing-page .seperator-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem; }
  .landing-page .seperator {
    position: relative;
    color: var(--highlight-500);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .landing-page .seperator:before {
      content: "\2022";
      position: absolute;
      left: -16px;
      top: 0; }
    .landing-page .seperator:after {
      content: "\2022";
      position: absolute;
      right: -16px;
      top: 0; }
  .landing-page .wrapping-up {
    max-width: 48rem;
    padding: 0 4rem;
    margin: 6rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .landing-page .wrapping-up > p {
      line-height: 1.7; }
    .landing-page .wrapping-up .request-access-btn-container {
      max-width: 260px;
      margin-top: 1.5rem;
      width: 100%; }
