[data-slate-leaf="true"] {
  position: relative; }
  [data-slate-leaf="true"][data-color="default"] {
    color: var(--default-color); }
  [data-slate-leaf="true"][data-color="grey"] {
    color: var(--grey-color); }
  [data-slate-leaf="true"][data-color="brown"] {
    color: var(--brown-color); }
  [data-slate-leaf="true"][data-color="orange"] {
    color: var(--orange-color); }
  [data-slate-leaf="true"][data-color="yellow"] {
    color: var(--yellow-color); }
  [data-slate-leaf="true"][data-color="green"] {
    color: var(--green-color); }
  [data-slate-leaf="true"][data-color="blue"] {
    color: var(--blue-color); }
  [data-slate-leaf="true"][data-color="purple"] {
    color: var(--purple-color); }
  [data-slate-leaf="true"][data-color="pink"] {
    color: var(--pink-color); }
  [data-slate-leaf="true"][data-color="red"] {
    color: var(--red-color); }
  [data-slate-leaf="true"][data-background-color="default"] {
    --background-color: var(--default-background-color); }
  [data-slate-leaf="true"][data-background-color="grey"] {
    --background-color: var(--grey-background-color); }
  [data-slate-leaf="true"][data-background-color="brown"] {
    --background-color: var(--brown-background-color); }
  [data-slate-leaf="true"][data-background-color="orange"] {
    --background-color: var(--orange-background-color); }
  [data-slate-leaf="true"][data-background-color="yellow"] {
    --background-color: var(--yellow-background-color); }
  [data-slate-leaf="true"][data-background-color="green"] {
    --background-color: var(--green-background-color); }
  [data-slate-leaf="true"][data-background-color="blue"] {
    --background-color: var(--blue-background-color); }
  [data-slate-leaf="true"][data-background-color="purple"] {
    --background-color: var(--purple-background-color); }
  [data-slate-leaf="true"][data-background-color="pink"] {
    --background-color: var(--pink-background-color); }
  [data-slate-leaf="true"][data-background-color="red"] {
    --background-color: var(--red-background-color); }
  [data-slate-leaf="true"][data-background-color]:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: -1; }

[data-has-content="true"] .text-node-placeholder {
  display: none; }

[data-has-placeholder="true"][data-has-content="false"] {
  display: inline-block;
  width: 100%; }
  [data-has-placeholder="true"][data-has-content="false"] > * {
    display: inline-block;
    width: 100%; }
  [data-has-placeholder="true"][data-has-content="false"] .text-node-placeholder {
    width: 100%;
    white-space: pre-wrap; }

.text-node-placeholder {
  pointer-events: none;
  display: inline-block;
  vertical-align: text-top;
  width: 0px;
  max-width: 100%;
  white-space: nowrap;
  opacity: 0.333;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: -2px;
  left: 0;
  color: var(--neutral-700); }

@-webkit-keyframes blink {
  from,
  to {
    background: transparent; }
  50% {
    background: #333333; } }

@keyframes blink {
  from,
  to {
    background: transparent; }
  50% {
    background: #333333; } }

.cursor {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .cursor::after {
    content: "";
    height: 98%;
    width: 1px;
    position: absolute;
    left: 2px;
    top: 0%;
    -webkit-animation: 1s blink step-end infinite;
            animation: 1s blink step-end infinite; }
