[data-scribe-editor] {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }

@-webkit-keyframes color {
  from {
    color: var(--neutral-900); }
  40% {
    color: var(--neutral-600); }
  50% {
    color: var(--neutral-600); }
  to {
    color: var(--neutral-900); } }

@keyframes color {
  from {
    color: var(--neutral-900); }
  40% {
    color: var(--neutral-600); }
  50% {
    color: var(--neutral-600); }
  to {
    color: var(--neutral-900); } }
  [data-scribe-editor] .tablet-status-container {
    grid-area: status;
    margin-left: 1.5rem;
    display: flex; }
  [data-scribe-editor] .tablet-status-badge {
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.5rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    --color: var(--danger-500);
    --border-color: var(--danger-300);
    border: 1px solid var(--border-color);
    color: var(--color); }
    [data-scribe-editor] .tablet-status-badge[data-archived="true"] {
      --color: var(--neutral-500);
      --border-color: var(--neutral-300); }
  [data-scribe-editor] .avatar-container {
    grid-area: avatar;
    width: 38px;
    height: 38px;
    position: relative; }
    [data-scribe-editor] .avatar-container:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: radial-gradient(white, rgba(255, 255, 255, 0.8));
      border-radius: 50%;
      pointer-events: none; }
    [data-scribe-editor] .avatar-container:hover:before {
      -webkit-animation-name: grow-avatar-circle;
              animation-name: grow-avatar-circle;
      -webkit-animation-duration: 30s;
              animation-duration: 30s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }

@-webkit-keyframes grow-avatar-circle {
  from {
    transform: scale(1); }
  to {
    transform: scale(10); } }

@keyframes grow-avatar-circle {
  from {
    transform: scale(1); }
  to {
    transform: scale(10); } }
  [data-scribe-editor] .author-name {
    grid-area: name;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--neutral-800);
    margin-top: 2px;
    line-height: 1;
    margin-bottom: 2px; }
  [data-scribe-editor] .edited-at {
    grid-area: createdAt;
    font-size: 0.875rem;
    color: var(--neutral-600); }
  [data-scribe-editor] .created-at {
    grid-area: createdAt;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--neutral-500);
    line-height: 1; }
