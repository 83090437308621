.new-wiki-dialog {
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  height: 100%;
  width: 50vw;
  max-width: 550px;
  min-width: 380px;
  background: white;
  border-radius: 8px; }
  @media screen and (max-width: 400px) {
    .new-wiki-dialog {
      width: 100%;
      min-width: unset;
      border-radius: 0; } }
  .new-wiki-dialog .modal-heading {
    font-size: 2rem;
    font-weight: 700;
    margin: 0 0 2.5rem 0; }
  .new-wiki-dialog .modal-subheading {
    font-size: 1rem;
    font-weight: 700;
    color: var(--primary-500);
    margin: 0 0 0.5rem; }
  .new-wiki-dialog .helper-text {
    font-size: 0.875rem;
    font-weight: 600; }
  .new-wiki-dialog .chip-section {
    margin: 1.5rem 0 0 0;
    display: flex;
    align-items: center;
    position: relative; }
    .new-wiki-dialog .chip-section > :not(:first-child):not(:last-child) {
      margin-right: 2rem; }
  .new-wiki-dialog .select-container {
    width: 240px;
    margin-top: 22px; }
  .new-wiki-dialog .new-wiki {
    color: var(--highlight-600); }
  .new-wiki-dialog .action-btns-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 2rem; }
  .new-wiki-dialog .seperator {
    display: block;
    height: 1px;
    border: 0;
    background: linear-gradient(to right, var(--attention-400) 60%, white);
    margin: 2rem 0;
    padding: 0; }
  .new-wiki-dialog .arrow-right {
    width: 0;
    height: 0;
    margin-right: 1.5rem;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 9px solid var(--attention-400);
    margin-left: 1.5rem;
    z-index: 2; }
  .new-wiki-dialog .connect-arrow-up {
    position: absolute;
    height: 2.8rem;
    width: 1px;
    top: 0.83rem;
    left: 0;
    background: var(--attention-400); }
  .new-wiki-dialog .connect-arrow-right {
    position: absolute;
    top: 0.81rem;
    height: 1px;
    width: 1.625rem;
    background: var(--attention-400); }
  .new-wiki-dialog .important {
    color: var(--highlight-500); }
