.collection-page {
  padding: 5rem 1rem 4rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }
  .collection-page .collection-page-content {
    max-width: 900px;
    margin: 0 auto;
    width: 100%; }
  .collection-page .related-entities-section {
    margin-top: 3rem; }
  .collection-page .related-entities-header {
    font-size: 1.125rem;
    margin-bottom: 0.75rem; }
  .collection-page .related-entities-list > :not(:last-child) {
    margin-bottom: 1.5rem; }
  .collection-page .collection-preview-container {
    max-width: 480px; }
  .collection-page .note-preview-container:not(:last-child) {
    margin-bottom: 2rem; }
  .collection-page .collection-preview {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer; }
    .collection-page .collection-preview .collection-preview-name {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.125rem; }
    .collection-page .collection-preview .collection-preview-metadata {
      font-size: 0.75rem;
      display: flex; }
      .collection-page .collection-preview .collection-preview-metadata > :not(:last-child) {
        margin-right: 15px; }
      .collection-page .collection-preview .collection-preview-metadata > :not(:first-child):after {
        content: "";
        width: 3px;
        height: 3px;
        background: var(--neutral-400);
        position: absolute;
        left: -9px;
        top: calc(50% - 1.5px);
        border-radius: 50%; }
    .collection-page .collection-preview .collection-preview-link {
      text-decoration: none;
      color: inherit;
      padding: 1.25rem 1rem;
      display: inline-block;
      width: 100%; }
    .collection-page .collection-preview .collection-preview-metadata-item {
      color: var(--neutral-500);
      position: relative; }
    .collection-page .collection-preview:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.1);
      transform: scale(1.01); }
  .collection-page .title-container {
    position: relative;
    display: flex; }
  .collection-page .bookmark-btn-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: -25px; }
    @media screen and (max-width: 1100px) {
      .collection-page .bookmark-btn-container {
        margin-left: 0; } }
  .collection-page .related-entities-header-section {
    display: flex; }
    .collection-page .related-entities-header-section > :first-child {
      margin-right: 0.75rem; }
  .collection-page .no-connections-message {
    max-width: 480px;
    text-align: center;
    margin-top: 0.75rem;
    margin-bottom: 4rem;
    color: var(--neutral-500);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .collection-page .archived-pages-seperator {
    color: var(--neutral-200);
    border-style: solid;
    border-width: 1px;
    margin: 6rem 0 1rem; }

.collection-name-input {
  font-size: 2.25rem;
  font-weight: 700;
  border: none;
  padding: 0;
  width: 100%; }
  .collection-name-input:focus {
    outline: none; }

.note-preview {
  border: 1px solid #e6e8ea;
  border-radius: 0.5rem; }
  .note-preview .note-preview-link {
    text-decoration: none;
    color: inherit;
    padding: 1rem;
    display: inline-flex;
    width: 100%;
    flex-direction: column; }
  .note-preview .prevent-pointer-events {
    pointer-events: none; }
  .note-preview .limit-height {
    max-height: 300px;
    overflow: hidden;
    position: relative; }
    .note-preview .limit-height:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 16px;
      background: linear-gradient(0deg, white, transparent);
      z-index: 6; }
  .note-preview .read-more-link {
    align-self: flex-end;
    margin-right: 4rem;
    margin-top: 1rem;
    color: var(--primary-500);
    transition: all 0.1s; }
    .note-preview .read-more-link:hover {
      color: var(--neutral-700); }
    .note-preview .read-more-link svg {
      vertical-align: middle;
      margin-left: 4px;
      position: relative;
      top: -1px; }
