[data-vertical-timeline] {
  position: relative; }
  [data-vertical-timeline]:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 4px;
    background: var(--neutral-800);
    border-radius: 2px; }
  [data-vertical-timeline][data-columns="1"]:before {
    left: 8px;
    transform: translateX(-50%); }
  [data-vertical-timeline][data-columns="1"][data-content-position="left"]:before {
    right: 8px;
    left: unset;
    transform: translateX(50%); }
  [data-vertical-timeline][data-columns="2"]:before {
    left: 50%;
    transform: translateX(-50%); }
  [data-vertical-timeline] .vertical-timeline-row {
    position: relative; }
    [data-vertical-timeline] .vertical-timeline-row:not(:last-child) {
      margin-bottom: 2rem; }
  [data-vertical-timeline][data-columns="1"] .vertical-timeline-element-container {
    padding-left: 32px; }
  [data-vertical-timeline][data-columns="1"][data-content-position="left"] .vertical-timeline-element-container {
    padding-right: 32px;
    justify-content: flex-end; }
  [data-vertical-timeline][data-columns="2"] .vertical-timeline-row:nth-child(odd) .vertical-timeline-element-container {
    padding-right: calc(50% + 8px + 16px);
    justify-content: flex-end; }
  [data-vertical-timeline][data-columns="2"] .vertical-timeline-row:nth-child(even) .vertical-timeline-element-container {
    padding-left: calc(50% + 8px + 16px); }
  [data-vertical-timeline] .vertical-timeline-elements {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 25px; }
    [data-vertical-timeline] .vertical-timeline-elements > :first-child [data-vertical-timeline-element]:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 50%;
      top: 0;
      right: -24px;
      transform: translateX(50%);
      background: white; }
    [data-vertical-timeline] .vertical-timeline-elements > :last-child [data-vertical-timeline-element]:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 50%;
      top: 50%;
      right: -24px;
      transform: translateX(50%);
      background: white; }
    [data-vertical-timeline] .vertical-timeline-elements > :first-child [data-vertical-timeline-element][data-position="right"]:after {
      left: -24px;
      transform: translateX(-50%); }
    [data-vertical-timeline] .vertical-timeline-elements > :last-child [data-vertical-timeline-element][data-position="right"]:after {
      left: -24px;
      transform: translateX(-50%); }
  [data-vertical-timeline] .vertical-timeline-element-container {
    position: relative;
    width: 100%;
    display: flex; }
  [data-vertical-timeline] .line-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.875rem;
    pointer-events: none;
    display: none; }
    [data-vertical-timeline] .line-label[data-position="right"] {
      left: calc(50% + 8px + 16px);
      display: inline-block; }
    [data-vertical-timeline] .line-label[data-position="left"] {
      right: calc(50% + 8px + 16px);
      display: inline-block; }
  [data-vertical-timeline] .line-marker {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: var(--neutral-800);
    z-index: 2; }
    [data-vertical-timeline] .line-marker:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid var(--primary-400); }
  [data-vertical-timeline][data-columns="1"] .line-marker {
    left: 8px; }
  [data-vertical-timeline][data-columns="1"][data-content-position="left"] .line-marker {
    right: 0;
    left: unset;
    transform: translate(0, -50%); }
  [data-vertical-timeline][data-columns="2"] .line-marker {
    left: 50%; }
