.actions-sidebar {
  --actions-sidebar-width: 360px;
  position: fixed;
  z-index: 8;
  display: flex;
  flex-direction: column;
  width: var(--actions-sidebar-width);
  top: 54px;
  bottom: 0;
  right: calc(-1 * var(--actions-sidebar-width));
  transition: transform 0.2s ease-out;
  will-change: transform;
  border-left: 1px solid var(--neutral-300);
  box-shadow: inset 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
  background: white; }
  @media screen and (max-width: 360px) {
    .actions-sidebar {
      --actions-sidebar-width: 100vw;
      transition: none; } }
  .actions-sidebar[data-minimized="false"] {
    -webkit-animation-name: actions-sidebar-appear;
            animation-name: actions-sidebar-appear;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .actions-sidebar[data-minimized="true"] {
    visibility: hidden; }

@-webkit-keyframes actions-sidebar-appear {
  from {
    opacity: 0;
    transform: translateX(calc(-0.95 * var(--actions-sidebar-width))); }
  to {
    opacity: 1;
    transform: translateX(calc(-1 * var(--actions-sidebar-width))); } }

@keyframes actions-sidebar-appear {
  from {
    opacity: 0;
    transform: translateX(calc(-0.95 * var(--actions-sidebar-width))); }
  to {
    opacity: 1;
    transform: translateX(calc(-1 * var(--actions-sidebar-width))); } }
  .actions-sidebar .actions-sidebar-header {
    display: flex;
    justify-content: space-between;
    background: var(--neutral-100);
    padding: 1rem 0.5rem 1rem 1rem; }
    .actions-sidebar .actions-sidebar-header > h3 {
      font-size: 1.125rem;
      font-size: 700;
      padding: 0;
      margin: 0; }
  .actions-sidebar .close-button {
    display: flex;
    border: none;
    font-size: 2rem;
    line-height: 2rem;
    background: inherit;
    padding: 0;
    position: relative;
    z-index: 0; }
    .actions-sidebar .close-button > svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--neutral-500);
      transition: all 0.15s; }
    .actions-sidebar .close-button:hover > svg {
      color: var(--accent-300); }
    .actions-sidebar .close-button:focus {
      outline: none; }
      .actions-sidebar .close-button:focus > svg {
        color: var(--accent-300); }
      .actions-sidebar .close-button:focus:before {
        content: "";
        position: absolute;
        left: -20%;
        top: -20%;
        width: 140%;
        height: 140%;
        border-radius: 50%;
        background: var(--accent-100);
        z-index: -1; }
