.sub-script {
  position: relative;
  padding-right: 0.5rem;
  padding-left: 0.25rem;
  line-height: 1.375;
  font-family: -webkit-body;
  font-weight: 500;
  font-size: 0.875rem; }
  .sub-script:after {
    content: attr(data-subscript);
    position: absolute;
    bottom: 0.1rem;
    right: 0.2rem;
    font-size: 0.5rem;
    font-family: -webkit-body;
    font-weight: 600;
    line-height: 1; }
