[data-filter-chip] {
  border-radius: 1.25rem;
  padding: 0.25rem 0.875rem;
  background: white;
  color: var(--attention-600);
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid var(--attention-600);
  letter-spacing: 0.5px;
  position: relative; }
  [data-filter-chip][data-display-mode="false"] {
    cursor: pointer; }
  [data-filter-chip]:focus {
    outline: none; }
  [data-filter-chip]:focus:before {
    border: 2px solid var(--attention-400);
    pointer-events: none;
    border-radius: 2rem;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    top: -5px;
    content: "";
    left: -5px;
    position: absolute; }
  [data-filter-chip][data-selected="true"] {
    background: var(--attention-600);
    color: white; }
  [data-filter-chip][data-display-mode="false"][data-selected="true"]:focus {
    background: var(--attention-700);
    border: 1px solid var(--attention-700);
    color: white; }
