.connect-page-sidebar {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto; }
  .connect-page-sidebar .search-bar-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .connect-page-sidebar .search-result {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
    .connect-page-sidebar .search-result:hover, .connect-page-sidebar .search-result:focus-within, .connect-page-sidebar .search-result[data-highlighted="true"] {
      background: var(--neutral-100); }
  .connect-page-sidebar .scrollable-container {
    overflow: auto; }
  .connect-page-sidebar .no-existing-option-section {
    padding: 1.25rem 3rem; }
  .connect-page-sidebar .no-existing-option-message {
    color: var(--neutral-700);
    margin-bottom: 1rem; }
  .connect-page-sidebar .no-existing-option-actions > :not(:last-child) {
    margin-bottom: 0.5rem; }
  .connect-page-sidebar .create-new-entity-section {
    padding: 0 1.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .connect-page-sidebar .create-btn-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
