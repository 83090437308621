.profile-page {
  padding: 40px;
  flex: 1 1 auto; }
  @media (max-width: 1199px) {
    .profile-page {
      padding: 20px 30px 40px 30px; } }
  .profile-page .profile-details-section {
    max-width: 600px;
    margin: 0 auto;
    margin-top: calc(20px + 5vw);
    margin-bottom: 4rem;
    display: flex;
    justify-content: flex-start; }
    @media (max-width: 576px) {
      .profile-page .profile-details-section {
        justify-content: center; } }
  .profile-page[data-edit-mode="true"] .profile-details-section {
    justify-content: center; }
  .profile-page .profile-picture-container {
    width: 128px;
    height: 128px;
    margin-left: 0;
    margin-right: 2.5rem;
    transform: translateX(0%);
    will-change: transform; }
    @media (max-width: 576px) {
      .profile-page .profile-picture-container {
        width: 64px;
        height: 64px;
        margin-right: 1rem; } }
    @media (max-width: 400px) {
      .profile-page .profile-picture-container {
        flex: 1 0 auto; } }
  @media (max-width: 400px) {
    .profile-page[data-edit-mode="true"] .profile-picture-container {
      flex: none; } }
  .profile-page .pic-abs {
    position: absolute;
    width: 128px;
    height: 128px;
    left: 0;
    top: 0; }
    @media (max-width: 576px) {
      .profile-page .pic-abs {
        width: 64px;
        height: 64px; } }
  @media (max-width: 576px) {
    .profile-page[data-edit-mode="true"] .pic-abs {
      width: 128px;
      height: 128px; } }
  .profile-page[data-edit-mode="true"] .profile-picture-container {
    margin-right: 0; }
    @media (max-width: 576px) {
      .profile-page[data-edit-mode="true"] .profile-picture-container {
        width: 128px;
        height: 128px; } }
  .profile-page[data-edit-mode="true"] .profile-details {
    display: none; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .profile-page .profile-details {
    display: grid;
    -webkit-animation: fadein 0.8s;
            animation: fadein 0.8s;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    row-gap: 0.25rem;
    grid-template-areas: "name btn" "position position"; }
    @media (max-width: 576px) {
      .profile-page .profile-details {
        grid-template-areas: "name name" "position position" "btn btn"; } }
  .profile-page .profile-name {
    grid-area: name;
    margin-right: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.75rem; }
  .profile-page .edit-profile-btn-container {
    grid-area: btn;
    align-self: center;
    justify-self: center; }
    @media (max-width: 576px) {
      .profile-page .edit-profile-btn-container {
        width: 100%;
        max-width: 200px;
        margin-top: 20px; } }
    @media (max-width: 400px) {
      .profile-page .edit-profile-btn-container {
        max-width: none; } }
  .profile-page .career-position {
    grid-area: position;
    font-size: 0.875rem;
    color: var(--neutral-400);
    font-weight: 600; }
  .profile-page .tablet-list-section {
    max-width: 600px;
    margin: 0 auto; }
    .profile-page .tablet-list-section > * {
      margin-bottom: 3rem; }
  .profile-page .edit-page {
    max-width: 500px;
    margin: 0 auto; }
    .profile-page .edit-page .profile-picture-container {
      width: 128px;
      height: 128px;
      margin: 0 auto;
      margin-top: calc(20px + 5vw);
      margin-bottom: 2rem; }
    .profile-page .edit-page .profile-details-form > :not(:last-child) {
      margin-bottom: 0.625rem; }
  .profile-page .edit-form-btns {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end; }
    .profile-page .edit-form-btns > :not(:last-child) {
      margin-right: 1rem; }
  .profile-page .no-documents-message {
    text-align: center;
    padding: 1rem 1rem;
    margin: 0 -40px;
    background: var(--highlight-400); }
    @media (max-width: 1199px) {
      .profile-page .no-documents-message {
        margin: 0 -30px; } }
    .profile-page .no-documents-message > h2 {
      margin-bottom: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.3px; }
  .profile-page .create-document-btn-container {
    max-width: 230px;
    margin: 0 auto; }
