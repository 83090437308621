.footer {
  display: flex;
  justify-content: center; }
  .footer .logo-link {
    display: flex;
    text-decoration: none;
    color: var(--neutral-900);
    align-items: center; }
  .footer .scribe-icon {
    height: 1.25rem;
    margin-right: 3px; }
  .footer .logo {
    font-size: 1.25rem; }
  .footer .border-container {
    max-width: 1200px;
    margin: 0 auto; }
  .footer .content-container {
    display: flex;
    max-width: 37.5rem;
    margin: 60px 40px 0;
    padding: 40px 10vw;
    box-sizing: content-box;
    width: 100%;
    justify-content: center;
    margin-top: 60px;
    border-top: 1px solid var(--highlight-300); }
    @media screen and (max-width: 680px) {
      .footer .content-container {
        padding: 40px 0; } }
    @media screen and (max-width: 480px) {
      .footer .content-container {
        flex-direction: column; } }
    @media screen and (min-width: 480px) {
      .footer .content-container > :first-child {
        margin-right: 100px; } }
    @media screen and (min-width: 480px) and (max-width: 540px) {
      .footer .content-container > :first-child {
        margin-right: 65px; } }
  .footer .logo-section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 1.25rem; }
  .footer .links-section {
    display: flex; }
    @media screen and (max-width: 480px) {
      .footer .links-section {
        flex-direction: column;
        text-align: center; } }
  .footer .links-container {
    display: flex;
    flex-direction: column; }
    .footer .links-container.horizontal {
      flex-direction: row; }
    .footer .links-container > h4 {
      margin-top: 0;
      margin-bottom: 1rem; }
    .footer .links-container > ul {
      list-style: none;
      -webkit-padding-start: 0;
              padding-inline-start: 0;
      margin: 0; }
  @media screen and (max-width: 780px) and (min-width: 480px) {
    .footer .social-media-links {
      display: none; } }
  .footer .social-media-links > ul li:not(:first-child) > .link.icon {
    margin-top: 1rem; }
  @media screen and (max-width: 480px) {
    .footer .social-media-links > ul {
      display: flex;
      justify-content: center; }
      .footer .social-media-links > ul li:not(:first-child) > .link.icon {
        margin-top: 0;
        margin-left: 2rem; } }
  @media screen and (max-width: 480px) {
    .footer .company-title {
      display: none; } }
  .footer .links-container:not(:last-child) {
    margin-bottom: 1.5rem; }
  .footer .link {
    color: var(--neutral-800);
    text-decoration: none;
    line-height: 2.25rem; }
    .footer .link.icon {
      display: flex;
      transition: color 0.2s; }
    .footer .link.icon:hover {
      color: var(--highlight-600); }
    .footer .link > svg {
      width: 1.5rem;
      height: 1.5rem; }
  @media screen and (min-width: 480px) {
    .footer .links-container + .links-container {
      margin-left: 4rem; } }
  .footer .social-media-links-medium-screen {
    margin-top: 1.5rem;
    max-width: 85px;
    display: none; }
    @media screen and (max-width: 780px) and (min-width: 480px) {
      .footer .social-media-links-medium-screen {
        display: inherit; } }
    .footer .social-media-links-medium-screen > ul {
      display: flex;
      flex-wrap: wrap; }
      .footer .social-media-links-medium-screen > ul > li {
        width: 50%;
        display: flex; }
      .footer .social-media-links-medium-screen > ul > :nth-child(n + 3) {
        margin-top: 1rem; }
      .footer .social-media-links-medium-screen > ul > :nth-child(odd) {
        justify-content: flex-start; }
      .footer .social-media-links-medium-screen > ul > :nth-child(even) {
        justify-content: flex-end; }
