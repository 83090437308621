.bookmark {
  background: inherit;
  border: none;
  pointer-events: all;
  transition: color 0.3s;
  font-size: 1.125rem;
  padding: 0; }
  .bookmark[data-bookmarked="true"] {
    color: var(--accent-500);
    -webkit-animation-name: bookmark;
            animation-name: bookmark;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
    .bookmark[data-bookmarked="true"]:focus {
      color: var(--accent-400); }
  .bookmark[data-bookmarked="false"] {
    color: var(--neutral-600); }
    .bookmark[data-bookmarked="false"]:focus {
      color: var(--neutral-400); }
  .bookmark:focus {
    outline: none; }

@-webkit-keyframes bookmark {
  from {
    transform: scale(1); }
  30% {
    transform: scale(1.15); }
  80% {
    transform: scale(0.85); }
  to {
    transform: scale(1); } }

@keyframes bookmark {
  from {
    transform: scale(1); }
  30% {
    transform: scale(1.15); }
  80% {
    transform: scale(0.85); }
  to {
    transform: scale(1); } }
