.linker-popover {
  width: 270px;
  z-index: 10; }
  .linker-popover .linker-popover-content {
    padding: 0.875rem 0;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .linker-popover .linker-popover-content > :first-child {
      width: 100%; }
    .linker-popover .linker-popover-content[data-num-options="0"] {
      height: 2.875rem; }
  .linker-popover .no-results-text {
    font-weight: 600;
    padding: 0px 0.875rem 0 0.875rem;
    font-size: 0.875rem;
    color: var(--neutral-600);
    opacity: 0;
    -webkit-animation-name: no-results-popover-appear;
            animation-name: no-results-popover-appear;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .linker-popover .create-new-page-prompt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .linker-popover .arrow-down {
    -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
            animation: downarrow 0.6s infinite alternate ease-in-out;
    color: var(--highlight-500); }

@-webkit-keyframes downarrow {
  0% {
    transform: translateY(-1px);
    opacity: 0.7; }
  100% {
    transform: translateY(1px);
    opacity: 0.9; } }

@keyframes downarrow {
  0% {
    transform: translateY(-1px);
    opacity: 0.7; }
  100% {
    transform: translateY(1px);
    opacity: 0.9; } }

@-webkit-keyframes no-results-popover-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }

@keyframes no-results-popover-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }
  .linker-popover .tablet-list-heading {
    font-size: 1rem;
    font-weight: 600;
    color: var(--neutral-600);
    margin: 0 0 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    padding: 0px 1.25rem 0 0.875rem; }
  .linker-popover .tablet-list-item {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: background 120ms ease-in 0s;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: none;
    transition: color 0.2s;
    font-weight: 600;
    padding: 0px 1.25rem 0 0.875rem;
    font-size: 0.875rem;
    color: var(--neutral-700);
    border: none;
    cursor: pointer;
    width: 100%;
    line-height: 2.375rem;
    background: inherit; }
    .linker-popover .tablet-list-item[data-highlighted="true"] {
      background: var(--neutral-100); }
    .linker-popover .tablet-list-item:hover {
      color: var(--primary-500); }
    .linker-popover .tablet-list-item:focus {
      outline: none; }
