/*##################################
##### HTML and Body
##################################*/
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch; }

html {
  color: #333;
  font-family: "Muli", sans-serif;
  overflow-y: scroll; }

svg {
  font-family: "Muli", sans-serif; }

#__next {
  height: 100%; }

.no-scroll {
  overflow: hidden; }

.preload-transitions * {
  transition: none !important; }

*,
*::before,
*::after {
  box-sizing: border-box; }

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0; }

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none; }

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto; }

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit; }

button {
  cursor: pointer;
  background: inherit;
  border: none; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

a:focus {
  color: var(--primary-500); }

:root {
  --primary-100: #ecf6fd;
  --primary-200: #c0e0f6;
  --primary-300: #97c8ed;
  --primary-400: #54a5de;
  --primary-500: #3192d8;
  --primary-600: #277ab4;
  --primary-700: #21578c;
  --primary-800: #193d62;
  --primary-900: hsla(211, 60%, 14%, 1);
  --neutral-100: #f6f6f9;
  --neutral-200: #ecedef;
  --neutral-300: #c6ccd2;
  --neutral-400: #acb7be;
  --neutral-500: #8796a1;
  --neutral-600: #697986;
  --neutral-700: #525d66;
  --neutral-800: #35383b;
  --neutral-900: #2b2b2b;
  --success-100: #eafbf2;
  --success-200: #cbf1dd;
  --success-300: #8fe1b5;
  --success-400: #6fcf9b;
  --success-500: #42c780;
  --success-600: #27af67;
  --success-700: #1f8e53;
  --success-800: #18713e;
  --success-900: #145139;
  --danger-100: #fce8e9;
  --danger-200: #f7c4c9;
  --danger-300: #ec8d97;
  --danger-400: #e5616e;
  --danger-500: #dd3647;
  --danger-600: #d02533;
  --danger-700: #b1202a;
  --danger-800: #8d1b23;
  --danger-900: #631318;
  --attention-100: #ebfefe;
  --attention-200: #d3f8f8;
  --attention-300: #aff3f3;
  --attention-400: #7beaea;
  --attention-500: #31d8d8;
  --attention-600: #1fbdbd;
  --attention-700: #178d8d;
  --attention-800: #127272;
  --attention-900: #0d4f4f;
  --highlight-100: #fcf6ee;
  --highlight-200: #f7e9d4;
  --highlight-300: #efd3a9;
  --highlight-400: #e5b975;
  --highlight-500: #dc9d42;
  --highlight-600: #cf8a26;
  --highlight-700: #ac7320;
  --highlight-800: #815618;
  --highlight-900: #5f3f12;
  --accent-100: #fbeff4;
  --accent-200: #eab8d2;
  --accent-300: #e199be;
  --accent-400: #d775a7;
  --accent-500: #c45490;
  --accent-600: #ae417b;
  --accent-700: #973a6c;
  --accent-800: #792f5a;
  --accent-900: #50213c;
  --default-color: var(--neutral-800);
  --grey-color: var(--neutral-500);
  --brown-color: var(--accent-700);
  --orange-color: var(--danger-400);
  --yellow-color: var(--neutral-800);
  --green-color: var(--success-800);
  --blue-color: var(--primary-600);
  --purple-color: var(--neutral-800);
  --pink-color: var(--accent-500);
  --red-color: var(--danger-500);
  --default-background-color: #fff;
  --grey-background-color: var(--neutral-200);
  --brown-background-color: var(--accent-700);
  --orange-background-color: var(--danger-400);
  --yellow-background-color: var(--neutral-800);
  --green-background-color: var(--success-200);
  --blue-background-color: var(--primary-200);
  --purple-background-color: var(--neutral-800);
  --pink-background-color: var(--accent-200);
  --red-background-color: var(--danger-100); }

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
  --reach-tooltip: 1; }

[data-reach-dialog-overlay] {
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-animation: fadein 0.3s;
          animation: fadein 0.3s;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center; }

[data-reach-dialog-content] {
  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
  border-radius: 8px; }

.text-block h1 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 2.75rem;
  transition: transform 0.3s; }

.text-block h2 {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  font-size: 2rem; }

.text-block h3 {
  margin-top: 1rem;
  margin-bottom: 0.125rem;
  font-size: 1.5rem; }

.text-block h4 {
  margin-top: 0.5rem;
  margin-bottom: 0.125rem;
  font-size: 1.25rem; }

.text-block p {
  padding: 3px 2px;
  line-height: 1.8rem;
  white-space: pre-wrap;
  word-break: break-word; }

.text-block p + p {
  margin-top: 1rem; }

.text-block ul {
  -webkit-padding-start: 2.25rem;
          padding-inline-start: 2.25rem;
  margin-top: 0.25rem; }

.text-block ol {
  -webkit-padding-start: 2rem;
          padding-inline-start: 2rem;
  margin-top: 0.25rem; }
  .text-block ol li {
    padding-left: 0.25rem; }

.text-block li {
  padding: 0.375rem 0;
  line-height: 1.8rem;
  font-size: 1rem;
  white-space: pre-wrap;
  word-break: break-word; }

.text-block a {
  color: var(--primary-500); }
  .text-block a:active {
    color: var(--highlight-600); }
