.search-page .search-bar-container {
  grid-area: search;
  display: flex;
  align-items: flex-end;
  max-width: 680px;
  margin-bottom: 2rem; }

.search-page .filter-chips {
  margin: -8px;
  overflow: hidden; }
  .search-page .filter-chips > * {
    margin: 8px;
    display: inline-block; }
  @media screen and (max-width: 600px) {
    .search-page .filter-chips {
      margin: -5px;
      max-height: 70px; }
      .search-page .filter-chips > * {
        margin: 5px; } }

.search-page .associated-tablets {
  max-width: 500px;
  margin-top: 40px; }
  .search-page .associated-tablets > h2 {
    border-bottom: 1px solid #d5d9dd;
    padding-bottom: 0.75rem;
    max-width: 600px;
    grid-area: title;
    margin: 0; }
  .search-page .associated-tablets .tablets {
    margin-top: 1.5rem; }
  .search-page .associated-tablets .tablet:not(:last-child) {
    margin-bottom: 1.5rem; }
