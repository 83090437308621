.tablet-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 96px 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto; }
  .tablet-page .tablet-metadata-section {
    margin-top: 3rem; }
  .tablet-page .collections {
    display: flex;
    margin-bottom: 0.5rem; }
    .tablet-page .collections > :not(:first-child) {
      margin-left: 1.25rem; }
  .tablet-page[data-edit-mode="true"] .editor-container {
    margin-bottom: 30px; }
  .tablet-page .collection-name {
    text-decoration: none;
    color: var(--neutral-600);
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.125rem;
    display: block;
    transition: opacity 0.3s;
    opacity: 1;
    padding: 0.5rem 0.75rem;
    background: var(--neutral-200);
    border-radius: 0.375rem; }
  .tablet-page[data-edit-mode="true"] .collection-name {
    opacity: 0;
    visibility: hidden; }
  .tablet-page .chips-section {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top: 1px solid var(--neutral-300); }
    .tablet-page .chips-section > :not(:last-child) {
      margin-bottom: 1.25rem; }
  .tablet-page .chip-section {
    display: flex; }
    .tablet-page .chip-section > :first-child {
      margin-right: 1.5rem; }
    .tablet-page .chip-section > :not(:first-child):not(:last-child) {
      margin-right: 0.5rem; }
  .tablet-page .chip-section-label {
    font-size: 0.875rem;
    font-weight: 600; }
  @media (min-width: 577px) and (max-width: 1199px) {
    .tablet-page {
      padding: 40px 30px 30vh; } }
  @media (max-width: 576px) {
    .tablet-page {
      padding: 40px 25px 30vh; } }
  .tablet-page .floating-extras {
    width: 100%;
    position: fixed;
    top: 120px;
    left: 0;
    display: flex;
    pointer-events: none; }
  .tablet-page .floating-extras-content {
    max-width: 750px;
    margin: 0 auto;
    width: 100%; }
  .tablet-page .clap-btn-container {
    width: 54px;
    height: 54px;
    display: flex;
    pointer-events: all; }
