.toolbar-list-item {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background 120ms ease-in 0s;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  transition: color 0.2s;
  font-weight: 600;
  padding: 0px 0.5rem;
  line-height: 28px;
  font-size: 0.875rem;
  color: var(--neutral-700);
  border: none;
  cursor: pointer;
  width: 100%;
  line-height: 2rem;
  background: inherit; }
  .toolbar-list-item[data-active="true"] {
    color: var(--primary-500); }
  .toolbar-list-item:hover {
    background: var(--neutral-100); }
  .toolbar-list-item:focus {
    outline: none; }
  .toolbar-list-item .rounded-border {
    border-radius: 4px;
    border: 1px solid var(--neutral-400);
    display: inline-block;
    width: 24px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem; }
  .toolbar-list-item > :not(:last-child) {
    margin-right: 0.625rem; }
