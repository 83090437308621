.blog-page {
  padding: 3.375rem 2rem 0;
  background: white; }
  .blog-page .heading {
    font-size: 3.75rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 1.25;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 8vh; }
    @media screen and (max-width: 880px) {
      .blog-page .heading {
        font-size: 2.75rem; } }
  .blog-page .heading-secondary-text {
    color: var(--neutral-400); }
  .blog-page .heading-primary-text {
    color: var(--neutral-700); }
  .blog-page .blog-previews {
    max-width: 680px;
    margin: 80px auto 0; }
    .blog-page .blog-previews > :not(:first-child) {
      border-top: 1px solid var(--highlight-300); }
    .blog-page .blog-previews > :not(:last-child) {
      margin-bottom: 40px; }
