.page {
  padding: 40px;
  flex: 1 1 auto; }
  .page[data-limit-width="true"] {
    max-width: 1000px;
    margin-left: 8%; }
    @media screen and (max-width: 840px) {
      .page[data-limit-width="true"] {
        margin-left: 0; } }
  .page[data-centered="true"] {
    margin: 0 auto; }
