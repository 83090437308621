.onboarding-workspace-page {
  padding: 2rem 4rem 0;
  background: white; }
  @media (max-width: 576px) {
    .onboarding-workspace-page {
      padding: 2rem 1.75rem 0; } }
  .onboarding-workspace-page main {
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 40px; }
    @media (max-width: 576px) {
      .onboarding-workspace-page main {
        margin-top: 40px; } }
  .onboarding-workspace-page header {
    border-bottom: 1px solid var(--neutral-300);
    text-align: center; }
  .onboarding-workspace-page .workspace-creation-section {
    width: 100%; }
  .onboarding-workspace-page .workspace-selection-section {
    width: 100%;
    display: flex;
    flex-direction: column; }
  .onboarding-workspace-page .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 0.25rem;
    color: var(--neutral-700);
    letter-spacing: 0.4px; }
  .onboarding-workspace-page .subtitle {
    font-size: 1.125rem;
    color: var(--neutral-500);
    margin: 0 0 1.5rem;
    font-weight: 500; }
  .onboarding-workspace-page .input-container {
    width: 100%;
    background: white; }
  .onboarding-workspace-page .create-workspace-btn-container {
    width: 120px;
    margin-left: auto;
    margin-top: 2rem; }
    .onboarding-workspace-page .create-workspace-btn-container svg {
      margin-left: 8px; }
  .onboarding-workspace-page .toggle-creation-btn-container {
    margin: 0 auto; }
  .onboarding-workspace-page .workspace-links {
    margin-bottom: 2rem; }
    .onboarding-workspace-page .workspace-links > :not(:last-child) {
      margin-bottom: 1rem; }
  .onboarding-workspace-page .workspace-link {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    color: white;
    background: linear-gradient(135deg, var(--primary-700) 0%, var(--primary-600) 150%);
    border-radius: 0.75rem;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.1s; }
    .onboarding-workspace-page .workspace-link:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35), 0 1px 3px rgba(0, 0, 0, 0.25);
      transform: scale(1.02); }
    .onboarding-workspace-page .workspace-link > a {
      text-decoration: none;
      color: inherit; }
  .onboarding-workspace-page .seperator {
    text-align: center;
    margin: 26px 0;
    font-size: 1.125rem; }
