.details-page {
  padding: 2rem 4rem 0;
  background: white; }
  @media (max-width: 576px) {
    .details-page {
      padding: 2rem 1.75rem 0; } }
  .details-page main {
    max-width: 640px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    padding-bottom: 60px; }
    @media (max-width: 576px) {
      .details-page main {
        margin-top: 40px; } }
  .details-page .detail-input {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
  .details-page header {
    border-bottom: 1px solid var(--neutral-300);
    text-align: center; }
  .details-page .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 0.25rem;
    color: var(--neutral-700);
    letter-spacing: 0.4px; }
  .details-page .subtitle {
    font-size: 1.125rem;
    color: var(--neutral-500);
    margin: 0 0 1.5rem;
    font-weight: 500; }
  .details-page .detail-input-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 1.25rem;
    grid-template-areas: "name name" "country phone" "company company" "role role"; }
    @media (max-width: 576px) {
      .details-page .detail-input-section {
        -moz-column-gap: 1rem;
             column-gap: 1rem; } }
  .details-page .name-input {
    grid-area: name; }
  .details-page .detail-input > .label-text {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.125rem; }
    .details-page .detail-input > .label-text.extra-margin {
      margin-bottom: 1.125rem; }
    .details-page .detail-input > .label-text > :first-child {
      margin-right: 0.5rem;
      font-size: 1rem; }
  .details-page .input-group {
    display: flex;
    background: white; }
    .details-page .input-group > :not(:last-child) {
      margin-right: 2rem; }
      @media (max-width: 576px) {
        .details-page .input-group > :not(:last-child) {
          margin-right: 1rem; } }
    .details-page .input-group > * {
      flex: 1 1 auto; }
  .details-page .phone-input {
    grid-area: phone; }
  .details-page .phone-input-placement {
    position: relative;
    top: 1rem; }
  .details-page .country-input {
    grid-area: country; }
  .details-page .company-input {
    grid-area: company; }
  .details-page .role-input {
    grid-area: role;
    flex-direction: column; }
    @media (max-width: 576px) {
      .details-page .role-input {
        margin: 0 -1.75rem; }
        .details-page .role-input > span {
          margin: 0 1.75rem; }
        .details-page .role-input .role-label-text {
          border-radius: 0.25rem; }
        .details-page .role-input .role-option:first-child .role-label-text {
          border-radius: 0 0.25rem 0.25rem 0;
          border-left: none !important; }
        .details-page .role-input .role-option:last-child .role-label-text {
          border-radius: 0.25rem 0 0 0.25rem;
          border-right: none !important; } }
  .details-page .role-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .details-page .role-inputs > :not(:last-child) {
      margin-right: 1.5rem; }
      @media (max-width: 400px) {
        .details-page .role-inputs > :not(:last-child) {
          margin-right: 0.75rem; } }
  .details-page .role-input-container {
    background: white;
    margin-top: 1rem; }
  .details-page .btn-container {
    width: 120px;
    margin-left: auto;
    margin-top: 4rem; }
    .details-page .btn-container svg {
      margin-left: 8px; }
  .details-page .phone-explanation {
    margin-left: 0.5rem;
    color: var(--neutral-600); }
    @media (max-width: 576px) {
      .details-page .phone-explanation {
        display: none; } }
  .details-page .optional-label {
    color: var(--neutral-500);
    font-size: 0.75rem;
    margin-left: 0.5rem;
    font-style: italic; }
  .details-page .role-option {
    position: relative;
    width: 180px;
    height: 3.5rem;
    cursor: pointer; }
    .details-page .role-option > input {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      left: 0; }
  .details-page .role-label-text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    transition: all 0.3s;
    text-align: center;
    padding: 0 0.5rem; }
  .details-page [type="radio"]:checked + .role-label-text {
    border: 1px solid var(--primary-500);
    background: var(--primary-500);
    color: white; }
  .details-page [type="radio"]:focus:not(:checked) + .role-label-text {
    border: 1px solid var(--primary-500); }
