.cm-s-scribe.CodeMirror {
  --gutters-background-color: var(--primary-100);
  --background-color: hsla(211, 60%, 14%, 1);
  --background-color-selected: rgba(95, 126, 151, 0.47);
  --background-color-active: #010e17;
  --gutter-color: var(--neutral-900);
  --all-else-color: var(--highlight-400);
  --cursor-color: var(--highlight-200);
  --keyword-color: hsla(302, 69%, 63%, 1);
  --operator-color: var(--highlight-200);
  --variable-color-1: hsla(174, 78%, 59%, 1);
  --variable-color-2: white;
  --variable-color-3: hsla(174, 78%, 59%, 1);
  --number-color: var(--highlight-500);
  --function-name-color: hsla(206, 70%, 68%, 1);
  --string-color: hsla(152, 74%, 62%, 1);
  --template-literal-color: hsla(152, 74%, 62%, 1);
  --comment-color: var(--neutral-500);
  --tag-color: #7fdbca;
  --attribute-color: #addb67;
  --property-color: var(--highlight-400);
  --qualifier-color: #addb67;
  --atom-color: hsla(355, 97%, 75%, 1);
  background-color: var(--background-color);
  color: var(--all-else-color);
  border-radius: 8px; }

.cm-s-scribe .CodeMirror-gutters {
  background: var(--gutters-background-color);
  color: var(--gutter-color);
  border: none; }

.cm-s-scribe .CodeMirror-guttermarker,
.cm-s-scribe .CodeMirror-guttermarker-subtle,
.cm-s-scribe .CodeMirror-linenumber {
  color: var(--gutter-color); }

.cm-s-scribe .CodeMirror-cursor {
  border-left: 2px solid var(--cursor-color); }

.cm-s-scribe div.CodeMirror-selected {
  background: var(--background-color-selected); }

.cm-s-scribe.CodeMirror-focused div.CodeMirror-selected {
  background: var(--background-color-selected); }

.cm-s-scribe .CodeMirror-line::-moz-selection, .cm-s-scribe .CodeMirror-line > span::-moz-selection, .cm-s-scribe .CodeMirror-line > span > span::-moz-selection {
  background: var(--background-color-selected); }

.cm-s-scribe .CodeMirror-line::selection,
.cm-s-scribe .CodeMirror-line > span::selection,
.cm-s-scribe .CodeMirror-line > span > span::selection {
  background: var(--background-color-selected); }

.cm-s-scribe .CodeMirror-activeline-background {
  background: var(--background-color-active); }

.cm-s-scribe .cm-keyword {
  color: var(--keyword-color); }

.cm-s-scribe .cm-operator {
  color: var(--operator-color); }

.cm-s-scribe .cm-variable {
  color: var(--variable-color-1); }

.cm-s-scribe .cm-variable-2 {
  color: var(--variable-color-2); }

.cm-s-scribe .cm-variable-3,
.cm-s-scribe .cm-type {
  color: var(--variable-color-3); }

.cm-s-scribe .cm-builtin {
  color: #ffcb6b; }

.cm-s-scribe .cm-atom {
  color: var(--atom-color); }

.cm-s-scribe .cm-number {
  color: var(--number-color); }

.cm-s-scribe .cm-def {
  color: var(--function-name-color); }

.cm-s-scribe .cm-string {
  color: var(--string-color); }

.cm-s-scribe .cm-string-2 {
  color: var(--template-literal-color); }

.cm-s-scribe .cm-comment {
  color: var(--comment-color); }

.cm-s-scribe .cm-tag {
  color: var(--tag-color); }

.cm-s-scribe .cm-tag.cm-bracket {
  color: var(--tag-color); }

.cm-s-scribe .cm-meta {
  color: var(--attribute-color); }

.cm-s-scribe .cm-attribute {
  color: var(--attribute-color); }

.cm-s-scribe .cm-property {
  color: var(--property-color); }

.cm-s-scribe .cm-qualifier {
  color: var(--qualifier-color); }

.cm-s-scribe .cm-error {
  color: white;
  background-color: #ff5370; }

.cm-s-scribe .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important; }
