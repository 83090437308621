.tablet-collection {
  border-radius: 4px;
  box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1);
  max-width: 800px;
  padding-bottom: 1.5rem; }
  .tablet-collection .header-section {
    display: grid;
    border-bottom: 3px solid var(--neutral-200);
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr auto;
    -moz-column-gap: 20px;
         column-gap: 20px;
    row-gap: 0.25rem;
    grid-template-areas: "title btn" "subtitle btn"; }
  .tablet-collection .collection-title {
    grid-area: title;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    padding: 16px 0 0 20px; }
  .tablet-collection .collection-subtitle {
    grid-area: subtitle;
    color: var(--neutral-500);
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 20px;
    margin-bottom: 1rem; }
  .tablet-collection .btn-container {
    grid-area: btn;
    padding: 16px 16px 0 0; }
  .tablet-collection .tablets-section {
    margin: 1.5rem 0;
    padding: 0 2rem; }
  .tablet-collection .view-more-link {
    color: var(--primary-500);
    padding-left: 2rem;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem; }
