.profile-picture-editor {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  width: 85vw;
  max-width: 750px;
  height: 80vh;
  max-height: 400px; }
  .profile-picture-editor[data-camera-mode="true"] {
    background: radial-gradient(var(--neutral-900), black);
    overflow: hidden !important; }
  .profile-picture-editor .profile-method-selection {
    padding: 3rem 2rem 2rem 2rem;
    width: 100%;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .profile-picture-editor .container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; }
  .profile-picture-editor .dropzone {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border: 2px solid var(--neutral-200);
    border-radius: 8px;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer; }
  .profile-picture-editor .dropzone:focus {
    border-color: var(--primary-300); }
  .profile-picture-editor .modal-heading {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: var(--neutral-600);
    letter-spacing: 0.4px;
    font-weight: 700; }
  .profile-picture-editor .upload-file-icon {
    width: 1.25rem;
    margin-right: 8px; }
  .profile-picture-editor .select-text {
    color: var(--primary-400);
    font-weight: 700;
    cursor: pointer; }
  .profile-picture-editor .close-button {
    position: absolute;
    right: 10px;
    border: none;
    font-size: 2rem;
    line-height: 2rem;
    top: 10px;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    z-index: 2;
    cursor: pointer; }
    .profile-picture-editor .close-button > svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--neutral-300); }
  .profile-picture-editor .seperator {
    text-align: center;
    margin: 26px 0;
    font-size: 1.125rem; }
  .profile-picture-editor .use-camera-btn {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid var(--primary-300);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    flex: 0 0 auto;
    background: white; }
  .profile-picture-editor .capture-camera-btn {
    min-width: 35px;
    max-width: 50px;
    width: 5vw;
    min-height: 35px;
    max-height: 50px;
    height: 5vw;
    border-radius: 50%;
    border: 1px solid var(--neutral-400);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);
    transform-origin: left;
    transition: all 0.3s; }
    .profile-picture-editor .capture-camera-btn:before {
      content: "";
      position: absolute;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      left: -7px;
      top: -7px;
      border: 4px solid white;
      border-radius: 50%;
      transition: all 0.3s; }
    .profile-picture-editor .capture-camera-btn:active {
      transform: scale(0.9) translateX(-50%); }
    .profile-picture-editor .capture-camera-btn:active:before {
      transform: scale(1.11); }
  .profile-picture-editor .camera-icon {
    width: 30%;
    min-width: 24px;
    height: 100%;
    color: var(--primary-500); }
  .profile-picture-editor > video {
    height: 100%; }

[data-reach-dialog-overlay] {
  display: flex; }
