.home-page {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  padding-top: 3rem;
  -moz-column-gap: 60px;
       column-gap: 60px;
  grid-template-areas: "newtablets outdated" "newtablets populartablets"; }
  .home-page .urgent-tablets-section {
    display: flex;
    justify-content: space-between; }
  .home-page .outdated-collection {
    grid-area: outdated; }
  .home-page .tablet-collection-container {
    width: 400px; }
  .home-page .tablets-container {
    padding: 1rem; }
  .home-page .new-tablets-list-container {
    margin-top: 1.25rem; }
  .home-page .new-tablets-section {
    grid-area: newtablets;
    max-width: 550px; }
    .home-page .new-tablets-section > h2 {
      border-bottom: 1px solid var(--neutral-300);
      padding-bottom: 0.75rem;
      max-width: 450px;
      grid-area: title;
      margin: 0; }
  .home-page .tablets {
    margin: 1.5rem 0;
    list-style: none;
    padding: 0; }
  .home-page .tablet:not(:last-child) {
    margin-bottom: 2rem; }
  .home-page .tablet {
    margin: 2rem 0;
    max-width: 425px; }
  .home-page .popular-tablets-container {
    grid-area: populartablets;
    margin-top: 50px; }
  .home-page .popular-tablets-section {
    max-width: 290px;
    border-radius: 4px;
    border: 1px solid var(--neutral-300);
    margin-left: auto; }
    .home-page .popular-tablets-section .heading-section {
      height: 70px;
      position: relative;
      background: var(--primary-200); }
      .home-page .popular-tablets-section .heading-section > h2 {
        position: absolute;
        bottom: 14px;
        left: 22px;
        margin: 0; }
    .home-page .popular-tablets-section .popular-tablets {
      padding: 1rem;
      list-style: none;
      margin: 0; }
