[data-combobox-option] {
  padding: 0 1.25rem;
  line-height: 30px;
  cursor: pointer;
  position: relative;
  z-index: 1; }
  [data-combobox-option] .option-text {
    display: inline-block;
    background: white;
    position: relative; }
  [data-combobox-option] .option-text:before {
    position: absolute;
    content: "";
    top: 0;
    left: -8px;
    width: calc(100% + 16px);
    height: 100%;
    background: white;
    z-index: -1; }
  [data-combobox-option][data-highlighted="true"], [data-combobox-option]:hover {
    color: var(--primary-500); }
  [data-combobox-option][data-highlighted="true"]:before {
    content: "";
    position: absolute;
    height: 1px;
    top: 52%;
    left: 6px;
    width: 93%;
    background: linear-gradient(to right, var(--primary-400), white);
    z-index: -1; }
