.triggers-page {
  padding-bottom: 60px; }
  .triggers-page .controls-section {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    -moz-column-gap: 20px;
         column-gap: 20px;
    row-gap: 2rem;
    padding-top: 3rem;
    grid-template-areas: "search buttons" "tags tags"; }
  .triggers-page .limit-width {
    max-width: 1000px;
    margin: 0 auto; }
  .triggers-page .search-bar-container {
    grid-area: search;
    display: flex;
    align-items: flex-end;
    width: 280px; }
  .triggers-page .action-btn-container {
    grid-area: buttons;
    display: flex; }
    .triggers-page .action-btn-container > :not(:last-child) {
      margin-right: 16px; }
  .triggers-page .trigger-tags-container {
    grid-area: tags; }
  .triggers-page .selected-trigger-section {
    margin: 3rem 0 0;
    display: flex;
    flex-direction: column;
    background: var(--highlight-100);
    padding: 1rem 20px; }
    .triggers-page .selected-trigger-section:not([data-selected-triggers="1"]) .trigger-btn:before {
      width: calc(1rem + 8px);
      height: calc(1rem + 16px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 14px; }
  .triggers-page .selected-trigger-content {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%; }
    .triggers-page .selected-trigger-content > :not(:last-child) {
      margin-bottom: 10px; }
  .triggers-page .selected-trigger {
    display: flex; }
  .triggers-page .triggered-text {
    font-size: 0.75rem;
    font-weight: 700;
    margin-left: 0.75rem;
    display: flex;
    align-items: center; }
  .triggers-page .trigger-icons {
    margin-left: 0.875rem;
    display: flex; }
  .triggers-page .trigger-btn {
    padding: 0;
    border: none;
    background: inherit;
    display: flex;
    justify-content: center;
    margin-left: 0.5rem;
    transition: color 0.3s;
    position: relative;
    color: var(--neutral-400);
    cursor: pointer; }
    .triggers-page .trigger-btn:focus {
      outline: none;
      color: white; }
    .triggers-page .trigger-btn:before {
      content: "";
      height: calc(100% + 2rem);
      width: 140%;
      position: absolute;
      left: -20%;
      top: -1rem;
      background: var(--highlight-500);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;
      cursor: pointer; }
    .triggers-page .trigger-btn:focus:before, .triggers-page .trigger-btn:active:before, .triggers-page .trigger-btn:hover:before {
      visibility: visible;
      pointer-events: auto;
      opacity: 1; }
    .triggers-page .trigger-btn:focus:before {
      background: var(--highlight-600); }
    .triggers-page .trigger-btn:active:before {
      background: var(--highlight-700); }
    .triggers-page .trigger-btn:focus:hover:before {
      background: var(--highlight-500); }
    .triggers-page .trigger-btn:hover:before {
      background: var(--highlight-300); }
  .triggers-page .trigger-icon {
    width: 1rem;
    z-index: 2; }
  .triggers-page .assistive-info-section {
    display: flex;
    margin-top: 80px; }
    .triggers-page .assistive-info-section > * {
      flex: 1 1 auto; }
    .triggers-page .assistive-info-section > :not(:last-child) {
      margin-right: 20px; }
  .triggers-page .timeline-container {
    margin-top: calc(1.5rem + 0.75rem); }
  .triggers-page .associated-tablets {
    max-width: 430px; }
    .triggers-page .associated-tablets > h2 {
      border-bottom: 1px solid #d5d9dd;
      padding-bottom: 0.75rem;
      max-width: 600px;
      grid-area: title;
      margin: 0; }
    .triggers-page .associated-tablets .associated-tablets-list-container {
      margin-top: 1.5rem; }

.activate-trigger-dialog,
.merge-trigger-dialog {
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  height: 100%;
  width: 80vw;
  max-width: 480px;
  background: white;
  border-radius: 8px; }
  .activate-trigger-dialog .modal-heading,
  .merge-trigger-dialog .modal-heading {
    font-size: 2rem;
    font-weight: 700; }
  .activate-trigger-dialog .helper-text,
  .merge-trigger-dialog .helper-text {
    font-size: 0.875rem;
    font-weight: 600; }
  .activate-trigger-dialog .action-btns-container,
  .merge-trigger-dialog .action-btns-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 2rem; }
  .activate-trigger-dialog .important,
  .merge-trigger-dialog .important {
    color: var(--highlight-500); }
  .activate-trigger-dialog > form,
  .merge-trigger-dialog > form {
    margin-top: 0.5rem;
    background: inherit; }

.merge-trigger-dialog .merge-options {
  display: flex;
  margin-top: 0.25rem; }
  .merge-trigger-dialog .merge-options > :not(:last-child) {
    margin-right: 0.5rem; }

.merge-trigger-dialog .merge-option {
  text-transform: uppercase;
  font-size: 11px;
  color: white;
  background: var(--primary-500);
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 700;
  letter-spacing: 0.2px;
  cursor: pointer;
  display: flex;
  align-items: center; }
