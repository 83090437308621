.clap-button {
  position: relative; }
  .clap-button .clap-count-user {
    position: absolute;
    top: -45%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    color: white;
    background: var(--success-600);
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .clap-button .clap-count-user[data-play-animation="true"] {
      -webkit-animation-name: clap-count;
              animation-name: clap-count;
      -webkit-animation-duration: 1.5s;
              animation-duration: 1.5s;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out; }
  .clap-button .clap-button-button {
    border: none;
    background: inherit;
    padding: 0;
    position: relative; }
    .clap-button .clap-button-button:focus {
      outline: none; }
    .clap-button .clap-button-button:disabled .clap-icon {
      fill: var(--neutral-400);
      stroke: white;
      cursor: not-allowed; }
    .clap-button .clap-button-button[data-has-clapped="true"]:disabled .clap-icon {
      fill: var(--neutral-600); }
  .clap-button .clap-icon {
    width: 24px;
    fill: none;
    stroke: var(--success-600);
    stroke-width: 2px; }
  .clap-button .clap-button-button[data-has-clapped="true"] .clap-icon {
    fill: var(--success-600);
    stroke: white;
    stroke-width: 1px; }

.confetti {
  left: calc(50% - var(--radius) * 1px - 1px);
  top: calc(50% - var(--radius) * 1px - 1px);
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .confetti.triangles {
    left: calc(50% - 2px);
    top: calc(50% - 3px); }
  .confetti .confetti-circle {
    fill: rgba(149, 165, 166, 0.5);
    stroke-opacity: 1;
    stroke-width: 2px;
    stroke: transparent;
    width: calc(var(--radius) * 2px);
    height: calc(var(--radius) * 2px); }
  .confetti[data-play-animation="true"] .confetti-circle {
    -webkit-animation-name: confetti-circle;
            animation-name: confetti-circle;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .confetti .confetti-triangle {
    fill: var(--accent-500);
    stroke-opacity: 1;
    stroke-width: 1;
    stroke: rgba(211, 84, 0, 0.5);
    width: calc(var(--width) * 1px);
    height: calc(var(--width) * 1px);
    transform: rotate(calc(var(--rotate) * 1deg - 90deg)); }
  .confetti[data-play-animation="true"] .confetti-triangle {
    -webkit-animation-name: confetti-triangle;
            animation-name: confetti-triangle;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .confetti .confetti-item {
    fill-opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0; }
    .confetti .confetti-item.confetti-triangle {
      top: calc(var(--y) * 14px);
      left: calc(var(--x) * 14px); }
    .confetti .confetti-item.confetti-circle {
      top: calc(var(--y) * 12px);
      left: calc(var(--x) * 12px); }

@-webkit-keyframes confetti-circle {
  from {
    transform: none;
    opacity: 0; }
  20% {
    opacity: 1; }
  90% {
    opacity: 0.9; }
  to {
    transform: translate(calc(var(--x) * 10px), calc(var(--y) * 10px)) scale(0.2);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@keyframes confetti-circle {
  from {
    transform: none;
    opacity: 0; }
  20% {
    opacity: 1; }
  90% {
    opacity: 0.9; }
  to {
    transform: translate(calc(var(--x) * 10px), calc(var(--y) * 10px)) scale(0.2);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@-webkit-keyframes confetti-triangle {
  from {
    transform: rotate(calc(var(--rotate) * 1deg - 90deg));
    opacity: 0; }
  20% {
    opacity: 1; }
  90% {
    opacity: 0.9; }
  to {
    transform: translate(calc(var(--x) * 16px), calc(var(--y) * 16px)) scale(0.2) rotate(calc(var(--rotate) * 1deg - 90deg));
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@keyframes confetti-triangle {
  from {
    transform: rotate(calc(var(--rotate) * 1deg - 90deg));
    opacity: 0; }
  20% {
    opacity: 1; }
  90% {
    opacity: 0.9; }
  to {
    transform: translate(calc(var(--x) * 16px), calc(var(--y) * 16px)) scale(0.2) rotate(calc(var(--rotate) * 1deg - 90deg));
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@-webkit-keyframes clap-count {
  from {
    transform: translate(-50%, 0);
    opacity: 0; }
  10% {
    opacity: 1; }
  30% {
    opacity: 1;
    transform: translate(-50%, -40%); }
  90% {
    opacity: 1;
    transform: translate(-50%, -40%); } }

@keyframes clap-count {
  from {
    transform: translate(-50%, 0);
    opacity: 0; }
  10% {
    opacity: 1; }
  30% {
    opacity: 1;
    transform: translate(-50%, -40%); }
  90% {
    opacity: 1;
    transform: translate(-50%, -40%); } }
