[data-error-message] {
  background: var(--danger-200);
  display: flex;
  padding: 0.375rem 0.625rem; }
  [data-error-message][data-full-screen="false"] {
    border-radius: 6px; }
  [data-error-message] p {
    font-size: 1rem;
    line-height: 1.7;
    margin-right: 0.5rem; }
  [data-error-message] .close-button {
    display: flex;
    justify-content: flex-end;
    border: none;
    background: inherit; }
    [data-error-message] .close-button > svg {
      width: 1rem; }
