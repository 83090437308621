.main-heading-container {
  position: relative; }
  .main-heading-container .bookmark-hook {
    position: absolute;
    left: -2rem;
    top: 50%;
    display: flex;
    transform: translateY(-50%);
    visibility: hidden; }
    .main-heading-container .bookmark-hook > * {
      visibility: visible; }
    @media (max-width: 576px) {
      .main-heading-container .bookmark-hook {
        left: -1.25rem !important; } }

[data-main-heading] {
  margin: 0 0 0.75rem 0;
  font-size: 2.75rem;
  transition: transform 0.3s; }
  [data-main-heading][data-has-placeholder="true"] {
    display: inline-block;
    width: 100%; }

[data-minor-heading] {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  font-size: 2rem; }

[data-sub-heading] {
  margin-top: 1.25rem;
  margin-bottom: 0.125rem;
  font-size: 1.5rem; }

[data-paragraph] {
  padding: 3px 2px;
  line-height: 1.8rem;
  white-space: pre-wrap;
  word-break: break-word; }

[data-type="paragraph"] + [data-type="paragraph"] {
  margin-top: 0.5rem; }

[data-bulleted-list] {
  -webkit-padding-start: 2.25rem;
          padding-inline-start: 2.25rem;
  margin-top: 0.25rem; }
  [data-bulleted-list] > [data-bulleted-list] {
    -webkit-padding-start: 1.5rem;
            padding-inline-start: 1.5rem; }
  [data-bulleted-list] > [data-numbered-list] {
    -webkit-padding-start: 1.5rem;
            padding-inline-start: 1.5rem; }
  [data-bulleted-list] > [data-check-list] > ul {
    -webkit-padding-start: 0.25rem;
            padding-inline-start: 0.25rem; }

[data-numbered-list] {
  -webkit-padding-start: 2rem;
          padding-inline-start: 2rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem; }
  [data-numbered-list] [data-list-item] {
    padding-left: 0.25rem; }
  [data-numbered-list] > [data-bulleted-list] {
    -webkit-padding-start: 1.5rem;
            padding-inline-start: 1.5rem; }
  [data-numbered-list] > [data-numbered-list] {
    -webkit-padding-start: 1.5rem;
            padding-inline-start: 1.5rem; }
  [data-numbered-list] > [data-check-list] > ul {
    -webkit-padding-start: 0.25rem;
            padding-inline-start: 0.25rem; }

[data-list-item] {
  padding: 0.25rem 0;
  line-height: 1.8rem;
  font-size: 1rem;
  white-space: pre-wrap;
  word-break: break-word; }

[data-code-block] {
  margin: 1.25rem 0; }

[data-check-list] {
  padding: 0;
  margin: 0.75rem 0;
  border: none; }
  [data-check-list] > ul {
    -webkit-padding-start: 1rem;
            padding-inline-start: 1rem;
    margin: 0; }
    [data-check-list] > ul > [data-bulleted-list] {
      -webkit-padding-start: 3rem;
              padding-inline-start: 3rem; }
    [data-check-list] > ul > [data-numbered-list] {
      -webkit-padding-start: 3rem;
              padding-inline-start: 3rem; }
    [data-check-list] > ul > [data-check-list] > ul {
      -webkit-padding-start: 1.5rem;
              padding-inline-start: 1.5rem; }

[data-link] {
  color: var(--primary-500) !important;
  cursor: pointer; }
  [data-link]:hover {
    color: var(--primary-400) !important; }

[data-internal-link] {
  color: var(--highlight-600) !important;
  cursor: pointer;
  text-decoration: none; }
  [data-internal-link]:hover {
    color: var(--highlight-500) !important; }

.author-section {
  margin-bottom: 2rem;
  text-decoration: none;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto 1fr;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  grid-template-areas: "avatar name status" "avatar createdAt status"; }

[data-slate-node="element"][data-placeholder-visible="true"] [data-slate-zero-width] {
  vertical-align: top; }

[data-slate-editor="true"] {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }

[data-placeholder-visible="true"] br {
  display: none; }

[data-placeholder-visible="true"]:after {
  content: attr(data-placeholder-text);
  opacity: 1;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  color: var(--neutral-400);
  transition: opacity 0.1s; }

.editor-element-limit-width {
  max-width: 44rem;
  margin: 0 4rem;
  width: 100%; }
  @media (min-width: 577px) and (max-width: 1199px) {
    .editor-element-limit-width {
      margin: 0 3rem; } }
  @media (max-width: 576px) {
    .editor-element-limit-width {
      margin: 0 1.5rem; } }

.editor-element-center {
  display: flex;
  justify-content: center; }

[data-slate-node="element"] .editor-element-center {
  all: unset; }

[data-slate-node="element"] .editor-element-limit-width {
  all: unset; }

[data-list-item] + .block-buttons-positioner {
  left: calc(0px - 50px) !important;
  width: 30px !important; }

[data-minor-heading] + .block-buttons-positioner {
  top: 12px !important; }

[data-sub-heading] + .block-buttons-positioner {
  top: 4px !important; }

.check-list-item-element + .block-buttons-positioner {
  top: 2px !important; }

.block-buttons-container {
  position: relative; }
  .block-buttons-container:before {
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: absolute;
    left: -100px;
    top: 0;
    height: 100%;
    width: 100px;
    cursor: text; }
  .block-buttons-container[data-show="true"]:hover .block-buttons-positioner {
    visibility: visible;
    opacity: 1; }
  .block-buttons-container .block-buttons-positioner {
    position: absolute;
    left: calc(0px - 40px);
    width: 40px;
    top: 5px;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s; }
  .block-buttons-container .add-block-btn {
    color: var(--neutral-400);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 1.125rem; }
    .block-buttons-container .add-block-btn:focus {
      outline: none; }
    .block-buttons-container .add-block-btn:hover {
      background: var(--neutral-100); }
