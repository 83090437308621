.command-popover {
  width: 270px;
  z-index: 10; }
  .command-popover .command-popover-content {
    padding: 0.875rem 0; }
  .command-popover .classic-text {
    font-family: -webkit-body; }
  .command-popover .no-results-text {
    font-weight: 600;
    padding: 0px 0.875rem 0 0.875rem;
    font-size: 0.875rem;
    color: var(--neutral-600);
    opacity: 0;
    -webkit-animation-name: no-results-popover-appear;
            animation-name: no-results-popover-appear;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }

@-webkit-keyframes no-results-popover-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }

@keyframes no-results-popover-appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }
  .command-popover .command-popover-heading {
    font-size: 1rem;
    font-weight: 600;
    color: var(--neutral-600);
    margin: 0 0 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    padding: 0px 1.25rem 0 0.875rem; }
  .command-popover .internal-link-icon {
    height: 65%; }
  .command-popover .command-section:not(:first-child) {
    margin-top: 1rem; }
  .command-popover[data-mouse-moved="true"] .command-list-item:hover {
    color: var(--primary-500); }
    .command-popover[data-mouse-moved="true"] .command-list-item:hover .rounded-border > svg {
      fill: var(--primary-500); }
  .command-popover .command-list-item {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: background 120ms ease-in 0s;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: none;
    transition: color 0.2s;
    font-weight: 600;
    padding: 0px 1.25rem 0 0.875rem;
    font-size: 0.875rem;
    color: var(--neutral-700);
    border: none;
    cursor: pointer;
    width: 100%;
    line-height: 2.375rem;
    background: inherit; }
    .command-popover .command-list-item[data-highlighted="true"] {
      background: var(--neutral-100); }
    .command-popover .command-list-item:focus {
      outline: none; }
    .command-popover .command-list-item .rounded-border {
      border-radius: 4px;
      border: 1px solid var(--neutral-400);
      display: inline-block;
      width: 28px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem; }
      .command-popover .command-list-item .rounded-border > svg {
        fill: var(--neutral-800);
        stroke: white;
        transition: fill 0.2s; }
    .command-popover .command-list-item > :not(:last-child) {
      margin-right: 0.625rem; }
