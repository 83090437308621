.pricing-page {
  background: white; }
  .pricing-page .pricing-page-content {
    margin-top: 80px; }
  .pricing-page .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .pricing-page .banner {
    background: var(--primary-700);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    padding: 0.625rem 1rem;
    text-align: center; }
    @media screen and (max-width: 640px) {
      .pricing-page .banner {
        margin-top: 40px; } }
    .pricing-page .banner > h1 {
      margin: 0;
      font-weight: 600;
      letter-spacing: 1px; }
  .pricing-page .banner-subtext {
    font-weight: 600;
    margin-top: 0.75rem;
    padding: 0 1rem;
    text-align: center; }
  .pricing-page .pricing-cards-container {
    padding: 0 1rem;
    margin-top: 100px;
    justify-content: center; }
    @media screen and (max-width: 640px) {
      .pricing-page .pricing-cards-container {
        margin-top: 70px; } }
  .pricing-page .pricing-cards {
    padding: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 2rem;
    max-width: 900px;
    margin: 0 auto; }
  .pricing-page .pricing-card {
    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s; }
    .pricing-page .pricing-card:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.1);
      transform: scale(1.01); }
    .pricing-page .pricing-card[data-type="personal"] .pricing-card-classification {
      background: var(--success-200); }
    .pricing-page .pricing-card[data-type="team"] .pricing-card-classification {
      background: var(--primary-200); }
    .pricing-page .pricing-card[data-type="enterprise"] .pricing-card-classification {
      background: var(--primary-700);
      color: white; }
    .pricing-page .pricing-card[data-type="enterprise"] .pricing-card-price {
      font-size: 2.25rem;
      margin-top: calc(1.5rem + 8px); }
    .pricing-page .pricing-card[data-type="enterprise"] .pricing-card-features {
      margin-top: 3.125rem; }
  .pricing-page .pricing-card-classification {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600; }
  .pricing-page .pricing-card-content {
    display: flex;
    flex-direction: column;
    padding: 0 0.75rem 1.75rem;
    align-items: center; }
  .pricing-page .pricing-card-description {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.5; }
  .pricing-page .pricing-card-price {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 1.5rem;
    line-height: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex; }
  .pricing-page .pricing-card-price-info {
    font-size: 0.75rem;
    align-self: flex-end;
    color: var(--neutral-700);
    font-weight: 500;
    margin-left: 0.375rem;
    margin-bottom: 0.25rem; }
  .pricing-page .pricing-card-sign-up-btn {
    display: inline-flex;
    align-content: center;
    justify-content: center;
    background: var(--primary-500);
    color: white;
    border: 1px solid var(--primary-500);
    padding: 0.25rem 1.25rem;
    border-radius: 0.5rem;
    margin: 1rem 0 0; }
    .pricing-page .pricing-card-sign-up-btn:focus {
      outline: none;
      border: 1px solid var(--primary-400);
      background: var(--primary-400); }
    .pricing-page .pricing-card-sign-up-btn:hover {
      background: var(--primary-400);
      border: 1px solid var(--primary-400); }
  .pricing-page .pricing-card-features {
    padding: 0;
    margin: 1.75rem 0 0; }
    .pricing-page .pricing-card-features > li {
      line-height: 1.3;
      text-align: center;
      font-size: 0.875rem; }

.request-access-dialog {
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  height: 100%;
  max-height: 95vh;
  width: 80vw;
  max-width: 520px;
  background: white;
  border-radius: 8px; }
  .request-access-dialog .signup-form {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    position: relative; }
  .request-access-dialog .thank-you {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    justify-self: center;
    align-self: center;
    text-align: center;
    padding: 10px 10px;
    background: white; }
    @media screen and (max-width: 450px) {
      .request-access-dialog .thank-you {
        box-shadow: 2px 4px 8px 2px rgba(103, 103, 103, 0.1); } }
    .request-access-dialog .thank-you > :first-child {
      font-size: 1.25rem;
      color: var(--primary-500); }
    .request-access-dialog .thank-you > :last-child {
      font-size: 1.125rem;
      color: var(--neutral-500); }
    .request-access-dialog .thank-you > p {
      margin: 0; }
  .request-access-dialog .signup-form[data-requested-access="true"] .thank-you {
    visibility: visible;
    opacity: 1; }
  .request-access-dialog .signup-form[data-requested-access="true"] .input-container,
  .request-access-dialog .signup-form[data-requested-access="true"] .submit-btn-container {
    visibility: hidden;
    opacity: 0; }
  .request-access-dialog .submit-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem; }
  .request-access-dialog .add-members-dialog-title {
    margin-bottom: 0.5rem; }
  .request-access-dialog .add-members-dialog-explanation {
    margin-bottom: 2rem;
    line-height: 1.5; }

.scribe-dialog-content {
  transform: none !important; }
