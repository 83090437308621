.connect-app-wiki-page {
  padding: 3.375rem 5rem 2rem;
  background: white; }
  @media screen and (max-width: 640px) {
    .connect-app-wiki-page {
      padding: 3.375rem 2rem 2rem; } }
  .connect-app-wiki-page header {
    border-bottom: 1px solid var(--neutral-300);
    text-align: center; }
  .connect-app-wiki-page main {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    background: white; }
  .connect-app-wiki-page .brand {
    color: #277ab4; }
  .connect-app-wiki-page .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 0.25rem;
    color: var(--neutral-700);
    letter-spacing: 0.4px; }
  .connect-app-wiki-page .subtitle {
    font-size: 1.125rem;
    color: var(--neutral-500);
    margin: 0 0 1.5rem;
    font-weight: 500; }
  .connect-app-wiki-page .wiki-card-section {
    display: flex;
    margin-top: 60px;
    justify-content: space-between; }
    .connect-app-wiki-page .wiki-card-section > :not(:last-child) {
      margin-right: 40px; }
    @media screen and (max-width: 640px) {
      .connect-app-wiki-page .wiki-card-section {
        flex-direction: column; }
        .connect-app-wiki-page .wiki-card-section > .wiki-card {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: 180px; }
        .connect-app-wiki-page .wiki-card-section > :not(:first-child) {
          margin-top: 20px;
          margin-bottom: 20px; } }
  .connect-app-wiki-page .wiki-card {
    width: 280px;
    height: 320px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    transition: all 0.2s;
    text-decoration: none;
    color: var(--neutral-800); }
    .connect-app-wiki-page .wiki-card:not([data-integrated="true"]):hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
    .connect-app-wiki-page .wiki-card:not([data-integrated="true"]):hover > .wiki-name {
      opacity: 1;
      visibility: visible; }
    .connect-app-wiki-page .wiki-card[data-integrated="false"] {
      cursor: pointer; }
    .connect-app-wiki-page .wiki-card[data-theme="basecamp"] {
      background: #fbf7f1; }
      .connect-app-wiki-page .wiki-card[data-theme="basecamp"] .integrated-message {
        background: #d6993d; }
    .connect-app-wiki-page .wiki-card[data-theme="confluence"] {
      background: #e0e9fb; }
      .connect-app-wiki-page .wiki-card[data-theme="confluence"] .integrated-message {
        background: #5a8bed; }
  .connect-app-wiki-page .wiki-name {
    justify-self: end;
    text-align: center;
    position: absolute;
    bottom: 1rem;
    font-size: 1.125rem;
    width: 100%;
    left: 0;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden; }
  .connect-app-wiki-page .image-container {
    height: 60%;
    display: flex;
    width: 100%;
    justify-content: center; }
    .connect-app-wiki-page .image-container > img {
      width: 80%; }
  .connect-app-wiki-page .app-details {
    margin-top: 40px; }
    .connect-app-wiki-page .app-details > h2 {
      line-height: 1.7; }
  .connect-app-wiki-page .questions {
    color: var(--neutral-700);
    margin-top: 3.5rem; }
    .connect-app-wiki-page .questions > a {
      text-decoration: none;
      color: var(--primary-500); }
  .connect-app-wiki-page .error-message-container {
    max-width: 600px;
    margin: 2rem auto 0; }
  .connect-app-wiki-page .integrated-message {
    text-align: center;
    line-height: 2.5;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 4rem;
    color: white;
    font-weight: bold;
    font-size: 1.25rem;
    transition: all 3s;
    opacity: 0.01;
    -webkit-animation-name: appear;
            animation-name: appear;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
    @media screen and (max-width: 640px) {
      .connect-app-wiki-page .integrated-message {
        bottom: 1.25rem; } }

@-webkit-keyframes appear {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes appear {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }
  .connect-app-wiki-page .code-snippet {
    background: #2d2d2d;
    color: #cccccc;
    padding: 1rem;
    overflow-x: auto;
    border-radius: 6px; }
    .connect-app-wiki-page .code-snippet .code-line {
      display: block; }
    .connect-app-wiki-page .code-snippet .code-comment {
      color: #b8b8b8;
      cursor: pointer; }
      .connect-app-wiki-page .code-snippet .code-comment:hover {
        color: #d9d9d9; }
