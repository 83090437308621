.about-page {
  padding: 3.375rem 2rem 0;
  background: white; }
  .about-page .about-page-content {
    max-width: 680px;
    margin: 80px auto 0; }
  .about-page .key-problem {
    color: var(--highlight-600);
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0; }
