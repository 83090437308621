.blog-post-preview {
  display: flex;
  flex-direction: column; }
  .blog-post-preview .title {
    font-size: 2rem;
    font-weight: 500;
    color: var(--neutral-900);
    margin-bottom: 0.875rem; }
  .blog-post-preview .post-metadata {
    display: flex;
    margin-bottom: 2.125rem;
    align-items: flex-end; }
  .blog-post-preview .authors-avatars {
    margin-right: 0.75rem;
    display: flex; }
    .blog-post-preview .authors-avatars > a {
      display: flex; }
    .blog-post-preview .authors-avatars .author-avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 50%; }
  .blog-post-preview .authors-names-container a {
    text-decoration: none; }
  .blog-post-preview .author-date-container {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    line-height: 1.25; }
  .blog-post-preview .preview-text {
    line-height: 1.7;
    margin-bottom: 1.5rem; }
  .blog-post-preview .author-name {
    color: var(--primary-500); }
  .blog-post-preview .authored-at-container {
    color: var(--neutral-500); }
  .blog-post-preview .read-more-btn-container {
    display: flex;
    max-width: 200px;
    align-self: flex-end; }
    .blog-post-preview .read-more-btn-container > a {
      text-decoration: none; }
  .blog-post-preview .preview-image {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    max-height: 300px;
    margin-bottom: 2rem; }
  .blog-post-preview p + p {
    margin-top: 1rem; }
