[data-select] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  [data-select] > select {
    font-size: 1rem;
    padding: 0.75rem 1.875rem 0.75rem 0.75rem;
    border: 1px solid var(--neutral-400);
    border-radius: 5px;
    width: 100%;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: white;
    line-height: 1.25rem; }
    [data-select] > select:focus {
      border: 1px solid var(--primary-500);
      outline: none; }
    [data-select] > select:invalid {
      color: var(--neutral-400); }
  [data-select] .select-icon-container {
    position: absolute;
    right: 16px;
    top: 51%;
    transform: translateY(-50%);
    color: var(--neutral-500);
    display: flex; }
    [data-select] .select-icon-container svg {
      width: 12px;
      height: 16px; }
  [data-select] option[disabled] {
    color: var(--neutral-400); }
