.toolbar {
  display: inline-flex;
  align-items: stretch;
  position: absolute;
  height: 32px;
  opacity: 0;
  visibility: hidden;
  background: white;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.2;
  z-index: 100;
  border-radius: 4px;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px; }

@-webkit-keyframes toolbar-appear {
  from {
    transform: scale(0.95);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes toolbar-appear {
  from {
    transform: scale(0.95);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }
  .toolbar[data-show="true"] {
    visibility: visible;
    -webkit-animation-name: toolbar-appear;
            animation-name: toolbar-appear;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .toolbar[data-show="false"] {
    top: 0; }
  .toolbar .select-block-type-content,
  .toolbar .select-block-color {
    display: flex;
    padding-left: 0.125rem;
    align-items: center; }
    .toolbar .select-block-type-content > span,
    .toolbar .select-block-color > span {
      padding: 0 0.125rem; }
    .toolbar .select-block-type-content svg,
    .toolbar .select-block-color svg {
      width: 0.5rem;
      margin-left: 0.375rem;
      color: var(--neutral-400); }

.toolbar-tooltip-content .tooltip-label {
  font-weight: 600; }

.toolbar-tooltip-content .tooltip-command {
  font-weight: 600;
  color: var(--neutral-300);
  opacity: 0.6; }

.color-icon {
  font-size: 0.875rem !important; }

.color-icon[data-color="default"],
.select-block-color[data-color="default"] {
  color: var(--default-color); }

.color-icon[data-color="grey"],
.select-block-color[data-color="grey"] {
  color: var(--grey-color); }

.color-icon[data-color="brown"],
.select-block-color[data-color="brown"] {
  color: var(--brown-color); }

.color-icon[data-color="orange"],
.select-block-color[data-color="orange"] {
  color: var(--orange-color); }

.color-icon[data-color="yellow"],
.select-block-color[data-color="yellow"] {
  color: var(--yellow-color); }

.color-icon[data-color="green"],
.select-block-color[data-color="green"] {
  color: var(--green-color); }

.color-icon[data-color="blue"],
.select-block-color[data-color="blue"] {
  color: var(--blue-color); }

.color-icon[data-color="purple"],
.select-block-color[data-color="purple"] {
  color: var(--purple-color); }

.color-icon[data-color="pink"],
.select-block-color[data-color="pink"] {
  color: var(--pink-color); }

.color-icon[data-color="red"],
.select-block-color[data-color="red"] {
  color: var(--red-color); }

.select-block-color[data-background-color="default"] > span {
  background: var(--default-background-color); }

.select-block-color[data-background-color="grey"] > span {
  background: var(--grey-background-color); }

.select-block-color[data-background-color="brown"] > span {
  background: var(--brown-background-color); }

.select-block-color[data-background-color="orange"] > span {
  background: var(--orange-background-color); }

.select-block-color[data-background-color="yellow"] > span {
  background: var(--yellow-background-color); }

.select-block-color[data-background-color="green"] > span {
  background: var(--green-background-color); }

.select-block-color[data-background-color="blue"] > span {
  background: var(--blue-background-color); }

.select-block-color[data-background-color="purple"] > span {
  background: var(--purple-background-color); }

.select-block-color[data-background-color="pink"] > span {
  background: var(--pink-background-color); }

.select-block-color[data-background-color="red"] > span {
  background: var(--red-background-color); }

.color-icon[data-background-color="default"] {
  background: var(--default-background-color); }

.color-icon[data-background-color="grey"] {
  background: var(--grey-background-color); }

.color-icon[data-background-color="brown"] {
  background: var(--brown-background-color); }

.color-icon[data-background-color="orange"] {
  background: var(--orange-background-color); }

.color-icon[data-background-color="yellow"] {
  background: var(--yellow-background-color); }

.color-icon[data-background-color="green"] {
  background: var(--green-background-color); }

.color-icon[data-background-color="blue"] {
  background: var(--blue-background-color); }

.color-icon[data-background-color="purple"] {
  background: var(--purple-background-color); }

.color-icon[data-background-color="pink"] {
  background: var(--pink-background-color); }

.color-icon[data-background-color="red"] {
  background: var(--red-background-color); }

.classic-text {
  font-family: -webkit-body; }

.toolbar-list-container {
  padding-bottom: 0.5rem;
  overflow: auto; }
  .toolbar-list-container .toolbar-heading {
    font-weight: 600;
    color: var(--neutral-600);
    margin: 0.75rem 0 0.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .toolbar-list-container .toolbar-subheading {
    font-weight: 600;
    color: var(--neutral-600);
    margin: 0.75rem 0 0.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .toolbar-list-container > * {
    padding: 0 1.25rem 0 0.875rem; }

.toolbar-url-link-container {
  padding: 1rem 0.875rem 0;
  display: flex; }
  .toolbar-url-link-container .toolbar-url-link-btns-container {
    margin-left: 0.75rem; }
  .toolbar-url-link-container .toolbar-url-link-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem 0.5rem;
    cursor: pointer;
    border: none;
    display: inline-flex;
    background: inherit;
    height: 46px;
    align-items: center;
    color: var(--primary-500);
    border-radius: 0.25rem;
    outline: none;
    transition: color 0.5s ease-in; }
    .toolbar-url-link-container .toolbar-url-link-btn:hover {
      background: var(--primary-100); }
    .toolbar-url-link-container .toolbar-url-link-btn:focus {
      box-shadow: 0 0 0 1px var(--primary-200); }
    .toolbar-url-link-container .toolbar-url-link-btn[disabled] {
      color: var(--neutral-400);
      cursor: not-allowed; }
      .toolbar-url-link-container .toolbar-url-link-btn[disabled]:hover {
        background: var(--neutral-100); }
    .toolbar-url-link-container .toolbar-url-link-btn:not([disabled]).danger {
      color: var(--accent-400); }
      .toolbar-url-link-container .toolbar-url-link-btn:not([disabled]).danger:hover {
        background: var(--accent-100); }
      .toolbar-url-link-container .toolbar-url-link-btn:not([disabled]).danger:focus {
        box-shadow: 0 0 0 1px var(--accent-200); }
