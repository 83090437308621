.document-page {
  padding: 2rem 1rem 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }
  .document-page .metadata-input-container {
    max-width: 600px; }
  .document-page .metadata-input-container + .metadata-input-container {
    margin-top: 2rem; }
  .document-page .document-name-section {
    margin: auto;
    max-width: 1000px;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s; }
    .document-page .document-name-section[data-measured-screen-size="false"] {
      opacity: 0; }
  .document-page .limit-width {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%; }
  .document-page .limit-width-tablet-list {
    max-width: 600px; }
  .document-page .tablets-list-container {
    margin-top: 40px;
    padding-bottom: 5rem; }
  .document-page .wikis-view-mode {
    margin-top: 1rem; }
  .document-page .tablets-preview-container {
    margin: 4rem -1rem 0;
    background: linear-gradient(to bottom, transparent 1.125rem, var(--highlight-300) 1.125rem);
    padding-bottom: 1rem; }
  .document-page .tablets-preview-heading {
    font-size: 2rem;
    line-height: 1; }
    .document-page .tablets-preview-heading > span {
      padding: 0 1rem 0.125rem;
      background: white;
      border-radius: 6px; }
  .document-page .draft-tablets {
    margin-top: 1.5rem; }
  .document-page .editor-section {
    padding-top: 1.75rem;
    padding-bottom: 6rem;
    position: relative;
    flex: 1 1 auto;
    margin: 0 -1rem 0; }
  .document-page .editor-section-content {
    max-width: 700px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 3.5rem;
    grid-template-areas: "editor editor" "triggers btns"; }
  .document-page .editor-container {
    grid-area: editor; }
  .document-page .triggers-container {
    grid-area: triggers;
    align-self: center; }
  .document-page .buttons-container {
    grid-area: btns;
    display: flex;
    align-self: end; }
    .document-page .buttons-container > :first-child {
      margin-right: 1rem; }
  .document-page .document-tablet-preview-item + .document-tablet-preview-item {
    margin-top: 0.75rem; }
  .document-page .draft-tablets {
    overflow: auto;
    padding-bottom: 1rem; }
  .document-page .no-triggers-message {
    color: var(--neutral-500); }
  .document-page .document-name-btn-container {
    width: 120px; }
    .document-page .document-name-btn-container svg {
      margin-left: 8px; }

.document-tablet-preview {
  display: grid;
  box-shadow: 2px 4px 8px rgba(102, 102, 102, 0.1);
  background: white;
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.375rem;
  transition: all 0.2s;
  border: 2px solid white; }
  .document-tablet-preview[data-selected="true"] {
    border: 2px solid var(--primary-500); }
  .document-tablet-preview:hover {
    box-shadow: 2px 4px 8px rgba(51, 51, 51, 0.1), 2px 8px 20px rgba(77, 77, 77, 0.1); }
  .document-tablet-preview:focus {
    outline: none;
    border: 2px solid var(--primary-400); }
  .document-tablet-preview .title {
    font-size: 1.25rem;
    font-weight: 700; }
  .document-tablet-preview .tablet-triggers-container > :first-child {
    margin-right: 0.5rem; }
  .document-tablet-preview .triggers {
    list-style: none;
    display: inline-flex;
    padding: 0; }
    .document-tablet-preview .triggers > :not(:last-child) {
      margin-right: 0.5rem; }
  .document-tablet-preview .trigger {
    text-transform: uppercase;
    font-size: 11px;
    color: white;
    background: var(--primary-500);
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.2px; }
  .document-tablet-preview .metadata {
    font-size: 0.75rem;
    padding-top: 2px; }
