.toolbar-button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background 120ms ease-in 0s;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  transition: color 0.2s;
  font-weight: 600;
  padding: 0px 0.5rem;
  background: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  margin: 0; }
  .toolbar-button:not(:last-child) {
    border-right: 1px solid rgba(55, 53, 47, 0.09); }
  .toolbar-button[data-active="true"] {
    color: var(--primary-500); }
  .toolbar-button:hover {
    background: var(--neutral-100); }
  .toolbar-button:focus {
    outline: none; }
