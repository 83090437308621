[data-vertical-timeline-element] {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  position: relative;
  transition: background 0.3s, color 0.3s; }
  [data-vertical-timeline-element][data-highlighted="true"] {
    background: var(--attention-700);
    color: white; }
  [data-vertical-timeline-element] > time {
    margin-top: 0.5rem;
    font-size: 0.875rem; }
