.verify-page {
  padding: 0 2rem;
  background: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .verify-page .verify-page-content {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .verify-page .verify-page-content[data-change-email-mode="true"] {
      max-width: 500px; }
  .verify-page main {
    width: 100%;
    background: white;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .verify-page .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 0.75rem;
    color: var(--neutral-700);
    letter-spacing: 0.4px;
    text-align: center; }
  .verify-page .error-message-container {
    margin-bottom: 1rem;
    margin-top: 0.5rem; }
  .verify-page .helper-text {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 3rem; }
  .verify-page .btns-container {
    display: flex;
    justify-content: center; }
    .verify-page .btns-container > :not(:last-child) {
      margin-right: 1rem; }
    .verify-page .btns-container > button {
      max-width: 210px; }
  .verify-page .input-container {
    margin-bottom: 1rem;
    background: inherit; }
  .verify-page .change-email-btn-container {
    display: flex;
    justify-content: space-between; }
    .verify-page .change-email-btn-container > button {
      max-width: 210px; }

@-webkit-keyframes section-appear {
  from {
    transform: scale(0.95);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes section-appear {
  from {
    transform: scale(0.95);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }
  .verify-page .animate-appear {
    -webkit-animation-name: section-appear;
            animation-name: section-appear;
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
