[data-chip-input] {
  position: relative;
  display: flex;
  width: 100%; }
  [data-chip-input] .chip-input-section {
    position: relative;
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center; }
    [data-chip-input] .chip-input-section > :not(:last-child) {
      margin-right: 0.5rem; }
  [data-chip-input] > :first-child {
    margin-right: 1.5rem; }
  [data-chip-input][data-label-position="placeholder"] label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--neutral-400);
    z-index: 2;
    padding: 0 0.25rem;
    background: white;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; }
  [data-chip-input][data-label-position="placeholder"][data-has-value="true"] label,
  [data-chip-input][data-label-position="placeholder"][data-has-chips="true"] label {
    transform: translate(0, -1.5rem) scale(0.75);
    color: var(--neutral-500); }
  [data-chip-input][data-label-position="placeholder"][data-input-focused="true"] label {
    transform: translate(0, -1.5rem) scale(0.75);
    color: var(--primary-500); }
  [data-chip-input][data-size="large"] label {
    font-size: 1.125rem;
    font-weight: 600; }

@-webkit-keyframes input-line-appear {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(1);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }

@keyframes input-line-appear {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(1);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; } }
  [data-chip-input][data-edit-mode="true"] .chip-input-section:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid var(--neutral-500);
    pointer-events: none;
    -webkit-animation-name: input-line-appear;
            animation-name: input-line-appear;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  [data-chip-input][data-edit-mode="true"][data-disabled="true"] .chip-input-section:after {
    border-bottom: 1px solid var(--neutral-300); }
  [data-chip-input][data-edit-mode="true"] .chip-input-section:focus-within:after {
    border-bottom: 1px solid var(--primary-400); }

@-webkit-keyframes appear {
  0% {
    transform: translateX(-5%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes appear {
  0% {
    transform: translateX(-5%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }
  [data-chip-input] .edit-mode-btn {
    -webkit-animation-name: appear;
            animation-name: appear;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  [data-chip-input] .input-container {
    min-width: 70px;
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: 8px; }
  [data-chip-input] .chip-input-input {
    position: relative;
    border: none;
    line-height: 1.25rem;
    padding: 0 0.25rem;
    width: 100%; }
    [data-chip-input] .chip-input-input:focus {
      outline: none; }
    [data-chip-input] .chip-input-input:disabled {
      color: var(--neutral-500);
      cursor: not-allowed; }
  [data-chip-input][data-edit-mode="true"] .chip {
    margin-bottom: 8px; }
