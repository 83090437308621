[data-chip] {
  overflow: hidden;
  letter-spacing: 0.5px;
  position: relative;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 1.25rem;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  transition: background 0.2s, color 0.2s, border 0.2s;
  width: 100%;
  background: var(--chip-background);
  color: var(--chip-color);
  border: var(--chip-border); }
  [data-chip][data-color="attention"] {
    --primary-color: var(--attention-600); }
    [data-chip][data-color="attention"]:focus-within {
      --primary-color: var(--highlight-500); }
  [data-chip][data-color="highlight"] {
    --primary-color: var(--highlight-600); }
    [data-chip][data-color="highlight"]:focus-within {
      --primary-color: var(--highlight-500); }
  [data-chip][data-color="primary"] {
    --primary-color: var(--primary-500); }
    [data-chip][data-color="primary"]:focus-within {
      --primary-color: var(--primary-400); }
  [data-chip][data-outline="true"] {
    --chip-background: white;
    --chip-color: var(--primary-color);
    --chip-border: 1px solid var(--primary-color); }
  [data-chip][data-outline="false"] {
    --chip-background: var(--primary-color);
    --chip-color: white;
    --chip-border: 1px solid var(--primary-color); }
  [data-chip][data-size="medium"] {
    font-size: 0.75rem; }
  [data-chip][data-size="small"] {
    font-size: 0.75rem; }
  button[data-chip]:focus {
    --chip-background: var(--primary-color);
    --chip-color: white;
    --chip-border: 1px solid var(--primary-color);
    outline: none; }
  button[data-chip]:hover {
    --chip-background: var(--primary-color);
    --chip-color: white;
    --chip-border: 1px solid var(--primary-color); }
  [data-chip] .content-container {
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0.125rem 0.625rem;
    background: inherit;
    color: inherit;
    overflow: hidden; }
    [data-chip] .content-container:focus {
      outline: none; }
    [data-chip] .content-container[data-size="medium"] {
      padding: 0.25rem 0.875rem; }
  [data-chip] .chip-delete-icon {
    display: inline-flex;
    padding: 0.125rem;
    border-radius: 50%;
    background: var(--highlight-700);
    color: white;
    border: none;
    margin-right: 0.25rem;
    transition: background 0.1s; }
    [data-chip] .chip-delete-icon:focus {
      outline: none;
      background: var(--highlight-800); }
    [data-chip] .chip-delete-icon > svg {
      height: 0.625rem;
      width: 0.625rem; }
