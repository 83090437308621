.check-list-item-element {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  line-height: 1.8rem; }
  .check-list-item-element + .check-list-item-element {
    margin-top: 0.375rem; }
  .check-list-item-element .checkbox-container {
    margin-right: 0.75em;
    display: flex;
    align-items: center;
    height: 28px; }
    .check-list-item-element .checkbox-container > input {
      margin: 0;
      width: 1rem;
      height: 1rem;
      cursor: pointer; }
  .check-list-item-element .checkbox-list-item-text {
    flex: 1;
    opacity: 1;
    -webkit-text-decoration: "none";
            text-decoration: "none";
    line-height: 28px;
    transition: opacity 0.2s; }
    .check-list-item-element .checkbox-list-item-text:focus {
      outline: none; }
    .check-list-item-element .checkbox-list-item-text[data-checked="true"] {
      opacity: 0.666;
      -webkit-text-decoration: "line-through";
              text-decoration: "line-through"; }
