.reset-page {
  padding: 3.375rem 2rem 0;
  background: white; }
  .reset-page .page-content-container {
    max-width: 380px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12vh;
    margin-bottom: 80px; }
  .reset-page .reset-password-container {
    width: 100%;
    background: white; }
  .reset-page .reset-password-btn-container {
    margin-top: 1rem; }
  .reset-page .reset-password-description {
    margin-bottom: 1rem;
    font-weight: 600; }
  .reset-page .logo-link {
    display: flex;
    text-decoration: none;
    color: var(--neutral-900);
    align-items: center;
    margin-bottom: 3rem; }
  .reset-page .scribe-icon {
    height: 2rem;
    margin-right: 3px; }
  .reset-page .logo {
    font-size: 2rem; }
  .reset-page .success-message {
    margin-bottom: 1.5rem; }
